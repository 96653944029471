import React from "react";

/**
 * Numeric-only field input component (Formik component={NumericField})
 * Only allows non-zero integers or empty
 * @param field - field passed by Formik
 * @param form - form passed by Formik
 * @param props - other field props
 * @returns {JSX.Element}
 */
export const NumericField = ({ field, form, ...props }) => {
  const handleChange = (event) => {
    const { value } = event.target;
    if (/^[1-9]\d*$/.test(value) || value === '') {   // only allow digits, no leading zeros (or empty)
      form.setFieldValue(field.name, value);
    }
  };

  return <input {...field} {...props} onChange={handleChange} />;
};
