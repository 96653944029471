import {withAuth} from "reducers";
import URI from 'urijs';
import {RSAA} from 'redux-api-middleware';
import history from "../history";

export const PATIENT_LIST_REQUEST = '@@patient/PATIENT_LIST_REQUEST';
export const PATIENT_LIST_SUCCESS = '@@patient/PATIENT_LIST_SUCCESS';
export const PATIENT_LIST_FAILURE = '@@patient/PATIENT_LIST_FAILURE';

export const PATIENT_LIST_OPTIMIZED_REQUEST = '@@patient/PATIENT_LIST_OPTIMIZED_REQUEST';
export const PATIENT_LIST_OPTIMIZED_SUCCESS = '@@patient/PATIENT_LIST_OPTIMIZED_SUCCESS';
export const PATIENT_LIST_OPTIMIZED_FAILURE = '@@patient/PATIENT_LIST_OPTIMIZED_FAILURE';

export const PATIENT_DETAIL_REQUEST = '@@patient/PATIENT_DETAIL_REQUEST';
export const PATIENT_DETAIL_SUCCESS = '@@patient/PATIENT_DETAIL_SUCCESS';
export const PATIENT_DETAIL_FAILURE = '@@patient/PATIENT_DETAIL_FAILURE';

export const PATIENT_CREATE_REQUEST = '@@patient/PATIENT_CREATE_REQUEST';
export const PATIENT_CREATE_SUCCESS = '@@patient/PATIENT_CREATE_SUCCESS';
export const PATIENT_CREATE_FAILURE = '@@patient/PATIENT_CREATE_FAILURE';

export const PATIENT_UPDATE_REQUEST = '@@patient/PATIENT_UPDATE_REQUEST';
export const PATIENT_UPDATE_SUCCESS = '@@patient/PATIENT_UPDATE_SUCCESS';
export const PATIENT_UPDATE_FAILURE = '@@patient/PATIENT_UPDATE_FAILURE';

export const PATIENT_DELETE_REQUEST = '@@patient/PATIENT_DELETE_REQUEST';
export const PATIENT_DELETE_SUCCESS = '@@patient/PATIENT_DELETE_SUCCESS';
export const PATIENT_DELETE_FAILURE = '@@patient/PATIENT_DELETE_FAILURE';

export const PATIENT_CONSENT_FORM_REQUEST = '@@patient/PATIENT_CONSENT_FORM_REQUEST';
export const PATIENT_CONSENT_FORM_SUCCESS = '@@patient/PATIENT_CONSENT_FORM_SUCCESS';
export const PATIENT_CONSENT_FORM_FAILURE = '@@patient/PATIENT_CONSENT_FORM_FAILURE';

export const PATIENT_CONSENT_FORM_DELETE_REQUEST = '@@patient/PATIENT_CONSENT_FORM_DELETE_REQUEST';
export const PATIENT_CONSENT_FORM_DELETE_SUCCESS = '@@patient/PATIENT_CONSENT_FORM_DELETE_SUCCESS';
export const PATIENT_CONSENT_FORM_DELETE_FAILURE = '@@patient/PATIENT_CONSENT_FORM_DELETE_FAILURE';

export const PATIENT_FILES_REQUEST = '@@patient/PATIENT_FILES_REQUEST';
export const PATIENT_FILES_SUCCESS = '@@patient/PATIENT_FILES_SUCCESS';
export const PATIENT_FILES_FAILURE = '@@patient/PATIENT_FILES_FAILURE';

export const PATIENT_FILES_DELETE_REQUEST = '@@patient/PATIENT_FILES_DELETE_REQUEST';
export const PATIENT_FILES_DELETE_SUCCESS = '@@patient/PATIENT_FILES_DELETE_SUCCESS';
export const PATIENT_FILES_DELETE_FAILURE = '@@patient/PATIENT_FILES_DELETE_FAILURE';

export const PATIENT_REPORTS_REQUEST = '@@patient/PATIENT_REPORTS_REQUEST';
export const PATIENT_REPORTS_SUCCESS = '@@patient/PATIENT_REPORTS_SUCCESS';
export const PATIENT_REPORTS_FAILURE = '@@patient/PATIENT_REPORTS_FAILURE';

export const PATIENT_REPORTS_DELETE_REQUEST = '@@patient/PATIENT_REPORTS_DELETE_REQUEST';
export const PATIENT_REPORTS_DELETE_SUCCESS = '@@patient/PATIENT_REPORTS_DELETE_SUCCESS';
export const PATIENT_REPORTS_DELETE_FAILURE = '@@patient/PATIENT_REPORTS_DELETE_FAILURE';

export const PATIENT_APPLICATION_GET_REQUEST = '@@patient/PATIENT_APPLICATION_GET_REQUEST';
export const PATIENT_APPLICATION_GET_SUCCESS = '@@patient/PATIENT_APPLICATION_GET_SUCCESS';
export const PATIENT_APPLICATION_GET_FAILURE = '@@patient/PATIENT_APPLICATION_GET_FAILURE';

export const PATIENT_APPLICATION_SUBMIT_REQUEST = '@@patient/PATIENT_APPLICATION_SUBMIT_GET_REQUEST';
export const PATIENT_APPLICATION_SUBMIT_SUCCESS = '@@patient/PATIENT_APPLICATION_SUBMIT_GET_SUCCESS';
export const PATIENT_APPLICATION_SUBMIT_FAILURE = '@@patient/PATIENT_APPLICATION_SUBMIT_GET_FAILURE';

export const PATIENT_APPLICATION_RESEND_REQUEST = '@@patient/PATIENT_APPLICATION_RESEND_REQUEST';
export const PATIENT_APPLICATION_RESEND_SUCCESS = '@@patient/PATIENT_APPLICATION_RESEND_SUCCESS';
export const PATIENT_APPLICATION_RESEND_FAILURE = '@@patient/PATIENT_APPLICATION_RESEND_FAILURE';

export const PATIENT_ARCHIVE_PUT_REQUEST = '@@patient/PATIENT_ARCHIVE_PUT_REQUEST';
export const PATIENT_ARCHIVE_PUT_SUCCESS = '@@patient/PATIENT_ARCHIVE_PUT_SUCCESS';
export const PATIENT_ARCHIVE_PUT_FAILURE = '@@patient/PATIENT_ARCHIVE_PUT_FAILURE';

export const TOGGLE_TEST_TYPE_MODAL = '@@patient/TOGGLE_TEST_TYPE_MODAL';

export const getPatientList = (params = {}) => {
  const uri = new URI('case/');
  const {page, case_id, ...other_params} = params;
  uri.setSearch({page: page, search: case_id, ...other_params});

  return ({
    [RSAA]: {
      endpoint: uri.normalizeSearch().toString(),
      method: 'GET',
      headers: withAuth({'Content-Type': 'application/json'}),
      types: [
        PATIENT_LIST_REQUEST, PATIENT_LIST_SUCCESS, PATIENT_LIST_FAILURE
      ]
    }
  })
};

export const getPatientOptimizedList = (params = {}) => {
  const uri = new URI('case/list_optimized/');
  const {page, case_id, ...other_params} = params;
  uri.setSearch({page: page, search: case_id, ...other_params});

  return ({
    [RSAA]: {
      endpoint: uri.normalizeSearch().toString(),
      method: 'GET',
      headers: withAuth({'Content-Type': 'application/json'}),
      types: [
        PATIENT_LIST_OPTIMIZED_REQUEST, PATIENT_LIST_OPTIMIZED_SUCCESS, PATIENT_LIST_OPTIMIZED_FAILURE
      ]
    }
  })
};


export const getPatientDetail = (patient_id) => {
  return({
    [RSAA]: {
      endpoint: 'patients/' + patient_id + '/',
      method: 'GET',
      headers: withAuth({'Content-Type': 'application/json'}),
      types: [
        PATIENT_DETAIL_REQUEST, PATIENT_DETAIL_SUCCESS, PATIENT_DETAIL_FAILURE
      ]
    }
  });
};

export const submitPatient = (data) => {
  const url =  `patients/${data.id ? data.id + '/' : ''}`;
  const method = data.id ? 'PATCH' : 'POST';

  if (method === 'PATCH') {
    delete data.created_at;
    delete data.updated_at;
  }

  return({
    [RSAA]: {
      endpoint: url,
      method: method,
      headers: withAuth({'Content-Type': 'application/json'}),
      types: data.id ? [
          PATIENT_UPDATE_REQUEST,
          PATIENT_UPDATE_SUCCESS,
          PATIENT_UPDATE_FAILURE
        ] :
        [
          PATIENT_CREATE_REQUEST,
          {
            type: PATIENT_CREATE_SUCCESS,
            payload: (action, state, res) => {
              return res.json().then(json => {
                history.push(`/patients/${json.id}?provider=${json.provider}`);
                return json;
              });
            }
          },
          PATIENT_CREATE_FAILURE
        ],
      body: JSON.stringify(data)
    }
  });
};

export const submitPatientConsentForm = (patient_id, data, file_name, old_file = null) => {
  const url =  `patient-consent-form/${patient_id}/`;

  let formData = new FormData();
  formData.append('file', data);
  formData.append('file_name', file_name);

  if (old_file){
    formData.append('old_file', old_file);
  }

  return({
    [RSAA]: {
      endpoint: url,
      method: 'POST',
      headers: withAuth({}),
      types: [PATIENT_CONSENT_FORM_REQUEST, PATIENT_CONSENT_FORM_SUCCESS, PATIENT_CONSENT_FORM_FAILURE],
      body: formData
    }
  });
};

export const deletePatientConsentForm = (patient_id, file_name) => {
  const url =  `patient-consent-form/${patient_id}/`;

  return({
    [RSAA]: {
      endpoint: url,
      method: 'DELETE',
      headers: withAuth({'Content-Type': 'application/json'}),
      types: [
        PATIENT_CONSENT_FORM_DELETE_REQUEST,
        PATIENT_CONSENT_FORM_DELETE_SUCCESS,
        PATIENT_CONSENT_FORM_DELETE_FAILURE
      ],
      body: JSON.stringify({'file_name': file_name})
    }
  });
};

export const submitPatientFile = (patient_id, data, file_name, type, old_file = null) => {
  const url =  `file/${patient_id}/`;

  let formData = new FormData();
  formData.append('file', data);
  formData.append('file_name', file_name);
  formData.append('type', type);

  if (old_file){
    formData.append('old_file', old_file);
  }

  return({
    [RSAA]: {
      endpoint: url,
      method: 'POST',
      headers: withAuth({}),
      types: [PATIENT_FILES_REQUEST, PATIENT_FILES_SUCCESS, PATIENT_FILES_FAILURE],
      body: formData
    }
  });
};

export const deletePatientFile = (patient_id, file, type) => {
  return({
    [RSAA]: {
      endpoint: `file/${patient_id}/`,
      method: 'DELETE',
      headers: withAuth({'Content-Type': 'application/json'}),
      types: [PATIENT_FILES_DELETE_REQUEST, PATIENT_FILES_DELETE_SUCCESS, PATIENT_FILES_DELETE_FAILURE],
      body: JSON.stringify({'file': JSON.stringify(file), type: type})
    }
  });
};

export const submitPatientReport = (patient_id, data, file_name, old_file = null) => {
  const url =  `patient-reports/${patient_id}/`;

  let formData = new FormData();
  formData.append('file', data);
  formData.append('file_name', file_name);

  if (old_file){
    formData.append('old_file', old_file);
  }

  return({
    [RSAA]: {
      endpoint: url,
      method: 'POST',
      headers: withAuth({}),
      types: [PATIENT_REPORTS_REQUEST, PATIENT_REPORTS_SUCCESS, PATIENT_REPORTS_FAILURE],
      body: formData
    }
  });
};

export const deletePatientReport = (patient_id, file_name) => {
  const url =  `patient-reports/${patient_id}/`;

  return({
    [RSAA]: {
      endpoint: url,
      method: 'DELETE',
      headers: withAuth({'Content-Type': 'application/json'}),
      types: [PATIENT_REPORTS_DELETE_REQUEST, PATIENT_REPORTS_DELETE_SUCCESS, PATIENT_REPORTS_DELETE_FAILURE],
      body: JSON.stringify({'file_name': file_name})
    }
  });
};

export const deletePatient = (id) => {
  return ({
    [RSAA]: {
      endpoint: 'patients/' + id + '/',
      method: 'DELETE',
      headers: withAuth({}),
      types: [
        PATIENT_DELETE_REQUEST,
        PATIENT_DELETE_SUCCESS,
        PATIENT_DELETE_FAILURE
      ]
    }
  })
};

export const getApplication = (id) => {
  return ({
    [RSAA]: {
      endpoint: 'patient-application/' + id + '/',
      method: 'GET',
      headers: {'Content-Type': 'application/json'},
      types: [
        PATIENT_APPLICATION_GET_REQUEST,
        PATIENT_APPLICATION_GET_SUCCESS,
        PATIENT_APPLICATION_GET_FAILURE
      ]
    }
  })
};

export const submitApplication = (id, gc_requested, gc_date_time) => {
  return ({
    [RSAA]: {
      endpoint: 'patient-application/' + id + '/',
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      types: [
        PATIENT_APPLICATION_SUBMIT_REQUEST,
        PATIENT_APPLICATION_SUBMIT_SUCCESS,
        PATIENT_APPLICATION_SUBMIT_FAILURE
      ],
      body: JSON.stringify({gc_requested: gc_requested, gc_date_time: gc_date_time})
    }
  })
};

export const reSendApplication = (id) => {
  return ({
    [RSAA]: {
      endpoint: 'patient-application-resend/' + id + '/',
      method: 'GET',
      headers: {'Content-Type': 'application/json'},
      types: [
        PATIENT_APPLICATION_RESEND_REQUEST,
        PATIENT_APPLICATION_RESEND_SUCCESS,
        PATIENT_APPLICATION_RESEND_FAILURE
      ]
    }
  })
};

export const putInArchive = (patient) => {
  return ({
    [RSAA]: {
      endpoint: 'patients/' + patient.id + '/',
      method: 'PUT',
      headers: withAuth({'Content-Type': 'application/json'}),
      types: [
        PATIENT_ARCHIVE_PUT_REQUEST,
        PATIENT_ARCHIVE_PUT_SUCCESS,
        PATIENT_ARCHIVE_PUT_FAILURE
      ],
      body: JSON.stringify(patient)
    }
  })
};

export const toggleTestTypeModal = (is_open, data = null) => {
  return ({
    type: TOGGLE_TEST_TYPE_MODAL,
    data,
    is_open
  })
};
