import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner } from 'reactstrap';
import axios from 'axios';
import {getToken} from "../../../auth/token";

/**
 * ClinicDropdown component - display current clinic; let user choose clinic with dropdown
 * @param {object} clinic - current clinic selected {id: provider id, company_name: clinic name}
 * @param {function} setClinic - function to set clinic state in caller with new clinic selected from dropdown
 *                               sets value {id: provider id, company_name: clinic name}
 * @returns {JSX.Element}
 */
const ClinicDropdown = ({clinic, setClinic}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [clinics, setClinics] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchClinics = async () => {
      try {
        const response = await axios({
          method: 'GET',
          responseType: 'json',
          baseURL: process.env.REACT_APP_API_HOST,
          url: '/provider-list',
          headers: {
            "Authorization": `Token ${getToken()}`
          }
        });
        if ('data' in response && 'Clinics' in response.data)
          setClinics(response.data.Clinics);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch clinics', error);
        setLoading(false);
      }
    };

    fetchClinics();
  }, []);

  return (
    <div>
      {loading ? (
        <Spinner color="primary" />
      ) : (
        <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(prevState => !prevState)}>
          <DropdownToggle color="" className='dataDropdown'>
            {clinic ? clinic.company_name : 'SELECT A CLINIC'}
          </DropdownToggle>
          <DropdownMenu>
            {clinics.length === 0 ?
              <DropdownItem disabled>No clinics available</DropdownItem>
            :
              clinics.map(clinic =>
                <DropdownItem key={clinic.id} onClick={() => setClinic(clinic)}>
                  {clinic.company_name}
                </DropdownItem>
              )
            }
          </DropdownMenu>
        </Dropdown>
      )}
    </div>
  );
};

export default ClinicDropdown;
