import React, {Component} from 'react';
import {
  IfAuthDistributor,
  IfAuthHasPermissions,
  IfAuthProviderOnly,
  IfAuthSuperAdminOrReadonly,
  Link
} from 'components/RBAC';
import {connect} from 'react-redux';
import {logout} from "actions/auth";
import {caseModalOpen, clinicianModalOpen, patientModalOpen, sampleModalOpen} from 'actions/modal';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import logo from 'images/logo-v2.svg';
import logo_dist from 'images/logo-dist.svg';
import classNames from 'classnames';
import IssueDialog from "layouts/Main/IssueDialog";

class SideBar extends Component {
  constructor(props) {
    super(props);

    this.openPatientModal = this.openPatientModal.bind(this);
    this.openClinicianModal = this.openClinicianModal.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);

    this.state = {
      dropdownOpen: false
    };
  }

  toggleDropdown() {
    this.setState({
      ...this.state,
      dropdownOpen: !this.state.dropdownOpen
    })
  }

  openPatientModal(e) {
    e.preventDefault();
    this.props.patientModalOpen();
  }

  openClinicianModal(e) {
    e.preventDefault();
    this.props.clinicianModalOpen();
  }

  clearLocalStorage() {
    window.localStorage.removeItem('caseTypesFilters')
    window.localStorage.removeItem('caseStatusFilter');
    window.localStorage.removeItem('consentSigned');
    window.localStorage.removeItem('invoicePaid');
  }

  render() {
    const pathname = this.props.route ? this.props.route.match.path : '/';

    return (
      <div className="sidebar">
        <div className="sidebar-container">
          <Link to={'/'} className="logo">
            <IfAuthProviderOnly>
              <img src={logo} alt="" style={{display: 'block', width: 180}}/>
            </IfAuthProviderOnly>
            <IfAuthDistributor>
              <img src={logo_dist} alt="" style={{display: 'block', width: 180}}/>
            </IfAuthDistributor>
          </Link>

          <ul className="nav">
            {/*<IfAuthProviderOnly>*/}
            {/*  <li className="nav-item">*/}
            {/*    <div style={{marginBottom: 12}}>*/}
            {/*      <ButtonDropdown direction="right" isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>*/}
            {/*        <DropdownToggle*/}
            {/*          caret color="info btn-create"*/}
            {/*          outline*/}
            {/*        >*/}
            {/*          <FontAwesomeIcon icon="plus"/>{' '}Create New*/}
            {/*        </DropdownToggle>*/}
            {/*        <DropdownMenu>*/}
            {/*          <DropdownItem onClick={this.props.caseModalOpen}>*/}
            {/*            Case*/}
            {/*          </DropdownItem>*/}
            {/*          <DropdownItem onClick={this.openPatientModal}>*/}
            {/*            Patient*/}
            {/*          </DropdownItem>*/}
            {/*          <DropdownItem onClick={this.openClinicianModal}>*/}
            {/*            Provider*/}
            {/*          </DropdownItem>*/}
            {/*        </DropdownMenu>*/}
            {/*      </ButtonDropdown>*/}
            {/*    </div>*/}
            {/*  </li>*/}
            {/*</IfAuthProviderOnly>*/}

            {/*<IfAuthGC>*/}
            {/*<li className="nav-item">*/}
            {/*<Link*/}
            {/*to={'/clinics'}*/}
            {/*className={classNames('nav-link', {*/}
            {/*active: ['/distributors'].includes(pathname)*/}
            {/*})}*/}
            {/*>*/}
            {/*<FontAwesomeIcon icon="project-diagram" fixedWidth className="sidebar-icon"/>{' '}Distributors*/}
            {/*</Link>*/}
            {/*</li>*/}
            {/*</IfAuthGC>*/}

            <IfAuthDistributor>
              <li className="nav-item"
                  onClick={this.clearLocalStorage}
              >
                <Link
                  to={'/clinics'}
                  className={classNames('nav-link', {
                    active: ['/clinics', '/clinics/:id'].includes(pathname)
                  })}
                >
                  <FontAwesomeIcon icon="building" fixedWidth className="sidebar-icon"/>{' '}Clinics
                </Link>
              </li>
            </IfAuthDistributor>

            <li className="nav-item"
                onClick={this.clearLocalStorage}
            >
              <Link
                to={'/providers'}
                className={classNames('nav-link', {
                  active: ['/providers'].includes(pathname)
                })}
              >
                <FontAwesomeIcon icon="user-md" fixedWidth className="sidebar-icon"/>{' '}
                Providers
              </Link>
            </li>

            <li className="nav-item"
                onClick={this.clearLocalStorage}
            >
              <Link
                to={'/nurses'}
                className={classNames('nav-link', {
                  active: ['/nurses'].includes(pathname)
                })}
              >
                <FontAwesomeIcon icon="user-md" fixedWidth className="sidebar-icon"/>{' '}
                Nurses
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to={'/patients'}
                className={classNames('nav-link', {
                  active: ['/patients', '/patients/:id'].includes(pathname)
                })}
              >
                <FontAwesomeIcon icon="users" fixedWidth className="sidebar-icon"/>{' '}Cases
              </Link>
            </li>

            <IfAuthSuperAdminOrReadonly>
              <li className="nav-item"
                  onClick={this.clearLocalStorage}
              >
                <Link
                  to={'/person'}
                  className={classNames('nav-link', {
                    active: ['/person', '/person/:id'].includes(pathname)
                  })}
                >
                  <FontAwesomeIcon icon="file-invoice" fixedWidth className="sidebar-icon"/>{' '}Patients
                </Link>
              </li>
            </IfAuthSuperAdminOrReadonly>

            {/*<li className="nav-item">*/}
            {/*<Link*/}
            {/*to={'/cases'}*/}
            {/*className={classNames('nav-link', {*/}
            {/*active: ['/', '/cases', '/cases/:id'].includes(pathname)*/}
            {/*})}*/}
            {/*>*/}
            {/*<FontAwesomeIcon icon="microscope" fixedWidth className="sidebar-icon"/>{' '}Cases*/}
            {/*</Link>*/}
            {/*</li>*/}

            {/*<li className="nav-item">*/}
            {/*<Link*/}
            {/*to={'/saliva-samples'}*/}
            {/*className={classNames('nav-link', {*/}
            {/*active: ['/saliva-samples', '/saliva-samples/:id'].includes(pathname)*/}
            {/*})}*/}
            {/*>*/}
            {/*<FontAwesomeIcon icon="vial" fixedWidth className="sidebar-icon"/>{' '}Saliva Samples*/}
            {/*</Link>*/}
            {/*</li>*/}


            <li className="nav-item"
                onClick={this.clearLocalStorage}
            >
              <Link
                to={'/kits'}
                className={classNames('nav-link', {
                  active: ['/kits'].includes(pathname)
                })}
              >
                <FontAwesomeIcon icon="boxes" fixedWidth className="sidebar-icon"/>{' '}
                Kits
              </Link>
            </li>

            <IfAuthSuperAdminOrReadonly>
              {process.env.REACT_APP_API_HOST.indexOf('127') !== -1 || process.env.REACT_APP_API_HOST.indexOf('localhost') !== -1 || process.env.REACT_APP_API_HOST.indexOf('stag') !== -1  ?
                <li className="nav-item"
                    onClick={this.clearLocalStorage}
                >
                  <Link
                    to={'/accessioning'}
                    className={classNames('nav-link', {
                      active: ['/accessioning'].includes(pathname)
                    })}
                  >
                    <FontAwesomeIcon icon="microscope" fixedWidth className="sidebar-icon"/>{' '}
                    Lab
                  </Link>
                </li>
                : null
              }
            </IfAuthSuperAdminOrReadonly>

            <li className="nav-item"
                onClick={this.clearLocalStorage}
            >
              <Link
                to={'/settings'}
                className={classNames('nav-link', {
                  active: ['/settings'].includes(pathname)
                })}
              >
                <FontAwesomeIcon icon="cog" fixedWidth className="sidebar-icon"/>{' '}
                Settings
              </Link>
            </li>

            <IfAuthHasPermissions permissionsList={['manage_roles']}>
              <li className="nav-item"
                  onClick={this.clearLocalStorage}
              >
                <Link
                  to={'/roles'}
                  className={classNames('nav-link', {
                    active: ['/roles'].includes(pathname)
                  })}
                >
                  <FontAwesomeIcon icon="id-card" fixedWidth className="sidebar-icon"/>{' '}
                  Roles
                </Link>
              </li>
            </IfAuthHasPermissions>

            <IfAuthHasPermissions permissionsList={['manage_users']}>
              <li className="nav-item"
                  onClick={this.clearLocalStorage}
              >
                <Link
                  to={'/users'}
                  className={classNames('nav-link', {
                    active: ['/users'].includes(pathname)
                  })}
                >
                  <FontAwesomeIcon icon="user" fixedWidth className="sidebar-icon"/>{' '}
                  Users
                </Link>
              </li>
            </IfAuthHasPermissions>

            <IfAuthSuperAdminOrReadonly>
              <li className="nav-item"
                  onClick={this.clearLocalStorage}
              >
                <Link
                  to={'/action-log'}
                  className={classNames('nav-link', {
                    active: ['/action-log'].includes(pathname)
                  })}
                >
                  <FontAwesomeIcon icon="list" fixedWidth className="sidebar-icon"/>{' '}
                  Action Log
                </Link>
              </li>
            </IfAuthSuperAdminOrReadonly>

            {/*<li className="nav-item">*/}
            {/*  <a*/}
            {/*    href={'https://gpclaboratory.com/order-brochures'}*/}
            {/*    target="_blank"*/}
            {/*    rel="noopener noreferrer"*/}
            {/*    className={classNames('nav-link')}*/}
            {/*  >*/}
            {/*    <FontAwesomeIcon icon="book-open" fixedWidth className="sidebar-icon"/>{' '}Order Brochures{' '}*/}
            {/*    <FontAwesomeIcon icon="external-link-alt" className="text-muted" style={{fontSize: 9, verticalAlign: 'super'}} />*/}
            {/*  </a>*/}
            {/*</li>*/}

            <li className="nav-item">
              <IssueDialog/>
            </li>
          </ul>

          {/*<ul className="nav bottom">*/}
          {/*<li className="nav-item">*/}
          {/*<Link to={'/login'} onClick={this.props.logout} className="nav-link">*/}
          {/*<FontAwesomeIcon icon="sign-out-alt" fixedWidth className="sidebar-icon"/>{' '}Logout*/}
          {/*({this.props.provider.first_name})*/}
          {/*</Link>*/}
          {/*</li>*/}
          {/*</ul>*/}
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    provider: state.auth
  }),
  {
    logout: logout,
    sampleModalOpen: sampleModalOpen,
    patientModalOpen: patientModalOpen,
    clinicianModalOpen: clinicianModalOpen,
    caseModalOpen: caseModalOpen
  }
)(SideBar)
