import React from 'react';
import {Button, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Moment from "moment/moment";
import {IfNotAuthSuperReadonly} from "../../RBAC";

const formatMoney = num => {
  if (num === null) return '';
  return new Intl.NumberFormat(`en-US`, {
      currency: `USD`,
      style: 'currency',
  }).format(num);
};

/**
 * @param patient
 * @returns {boolean} - true if patient (case) has no tests assigned yet
 */
const hasNoTests = patient => !(patient.pgt_a || patient.pgt_m || patient.pgt_s || patient.pgt_a_plus || patient.m2);

export default (props) => {
  const {patient} = props;

  return (
    <div>
      <Card>
        <CardHeader>
          <Row className="justify-content-between align-items-center">
            <Col>
              <h6 style={{margin: 0}}>
                Billing Section
              </h6>
            </Col>
            <Col>
              &nbsp;
            </Col>
            <Col>
              &nbsp;
            </Col>
            <Col md="auto">
              <IfNotAuthSuperReadonly>
                <Button
                  color="light"
                  size="sm"
                  onClick={() => props.onEdit()}
                >
                  <FontAwesomeIcon icon="pencil-alt"/> EDIT
                </Button>
              </IfNotAuthSuperReadonly>
            </Col>
            <Col md="auto">
              &nbsp;
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <p>
                <strong>Account Manager:</strong>{' '}<span className="text-muted"> {patient.account_manager}</span>
              </p>
              <p>
                <strong>Billing Type:</strong>{' '}<span className="text-muted"> {patient.billing_type}</span>
              </p>
              <p>
                <strong>Billing Email:</strong> {patient.billing_email}
              </p>
              <p>
                <strong>Set up Fee:</strong> {formatMoney(patient.setup_fee)}
              </p>
              <p>
                <strong>Setup Sent Date:</strong> {
                  patient.setup_sent_date ? (
                    <span>{Moment(patient.setup_sent_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}</span>
                  ) : '-'
                }
              </p>
              <p>
                <strong>Setup Paid Date:</strong> {
                  patient.setup_paid_date ? (
                    <span>{Moment(patient.setup_paid_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}</span>
                  ) : '-'
                }
              </p>
              <p>
                <strong>Per-Sample Fee:</strong> {formatMoney(patient.per_sample_fee)}
              </p>
              <p>
                <strong>Shipping Fee:</strong> {formatMoney(patient.shipping_fee)}
              </p>
              <p>
                <strong># of Biopsies:</strong> {patient.num_biopsies}
              </p>
              <p>
                <strong>Rebiopsy Note:</strong> {patient.billing_note}
              </p>
            </Col>
            <Col>
              <p>
                <strong>Case Total Fee:</strong> {formatMoney(patient.case_total_fee)}
              </p>
              <p>
                <strong>Case Billed Amount:</strong> {formatMoney(patient.case_billed_amount)}
              </p>
              <p>
                <strong>Case Paid Amount:</strong> {formatMoney(patient.case_paid_amount)}
              </p>
              <p>
                <strong>Invoice Number:</strong> {patient.invoice_number}
              </p>
              <p>
                <strong>Invoice Billed Date:</strong> {
                patient.invoice_billed_date ? (
                  <span>{Moment(patient.invoice_billed_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}</span>
                ) : '-'
              }
              </p>
              <p>
                <strong>Invoice Paid Date:</strong> {
                patient.invoice_paid_date ? (
                  <span>{Moment(patient.invoice_paid_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}</span>
                ) : '-'
              }
              </p>
              {(patient.pgt_m || patient.pgt_s || hasNoTests(patient)) && (
                <>
                  <p>
                    <strong>Intake Fee:</strong> {formatMoney(patient.intake_fee)}
                  </p>
                  <p>
                    <strong>Intake Fee Sent Date:</strong> {
                    patient.intake_fee_sent_date ? (
                      <span>{Moment(patient.intake_fee_sent_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}</span>
                    ) : '-'
                  }
                  </p>
                  <p>
                    <strong>Intake Fee Paid Date:</strong> {
                    patient.intake_paid_date ? (
                      <span>{Moment(patient.intake_paid_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}</span>
                    ) : '-'
                  }
                  </p>
                  <p>
                    <strong>Admin &amp; Analysis Fee:</strong> {formatMoney(patient.admin_and_analysis_fee)}
                  </p>
                </>
              )}
            </Col>
          </Row>
          {patient.test_update_date ? (
            <>
            <hr />
            <div style={{fontWeight: 'bold', marginBottom: '10px'}}>After Tests Updated {Moment(patient.test_update_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}:</div>
            <Row>
              <Col>
                <p>
                  <strong>Updated Fee:</strong> {formatMoney(patient.fee_after_test_update)}
                </p>
                <p>
                  <strong>Updated Paid Amount:</strong> {formatMoney(patient.paid_amount_after_test_update)}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Updated Invoice Number:</strong> {patient.invoice_number_after_test_update}
                </p>
                <p>
                  <strong>Updated Invoice Billed Date:</strong> {
                  patient.invoice_billed_date_after_test_update ? (
                    <span>{Moment(patient.invoice_billed_date_after_test_update, 'YYYY-MM-DD').format('MM/DD/YYYY')}</span>
                  ) : '-'
                }
                </p>
                <p>
                  <strong>Updated Invoice Paid Date:</strong> {
                  patient.invoice_paid_date_after_test_update ? (
                    <span>{Moment(patient.invoice_paid_date_after_test_update, 'YYYY-MM-DD').format('MM/DD/YYYY')}</span>
                  ) : '-'
                }
                </p>
              </Col>
            </Row>
            </>
          ) : null}
          <div>
            <hr/>
            <div style={{fontWeight: 'bold', marginBottom: '10px'}}>Billing Structure</div>
            <table style={{borderCollapse: "unset"}}>
              <tbody>
              <tr>
                <td style={{verticalAlign: "top"}}>PGT - A</td>
                <td style={{verticalAlign: "top"}}>:</td>
                <td style={{verticalAlign: "top"}}>
                  <pre
                    style={{fontFamily: "Roboto,sans-serif", fontSize: "inherit", marginBottom: 0}}>{patient.billing_structure_a}</pre>
                </td>
              </tr>
              <tr>
                <td style={{verticalAlign: "top"}}>PGT - A+</td>
                <td style={{verticalAlign: "top"}}>:</td>
                <td style={{verticalAlign: "top"}}>
                  <pre style={{fontFamily: "Roboto,sans-serif", fontSize: "inherit", marginBottom: 0}}>{patient.billing_structure_aplus}</pre>
                </td>
              </tr>
              <tr>
                <td style={{verticalAlign: "top"}}>PGT - M</td>
                <td style={{verticalAlign: "top"}}>:</td>
                <td style={{verticalAlign: "top"}}>
                  <pre style={{fontFamily: "Roboto,sans-serif", fontSize: "inherit", marginBottom: 0}}>{patient.billing_structure_m}</pre>
                </td>
              </tr>
              <tr>
                <td style={{verticalAlign: "top"}}>PGT - SR</td>
                <td style={{verticalAlign: "top"}}>:</td>
                <td style={{verticalAlign: "top"}}>
                  <pre style={{fontFamily: "Roboto,sans-serif", fontSize: "inherit", marginBottom: 0}}>{patient.billing_structure_sr}</pre>
                </td>
              </tr>
              <tr>
                <td style={{verticalAlign: "top"}}>PGT - P</td>
                <td style={{verticalAlign: "top"}}>:</td>
                <td style={{verticalAlign: "top"}}>
                  <pre style={{fontFamily: "Roboto,sans-serif", fontSize: "inherit", marginBottom: 0}}>{patient.billing_structure_p}</pre>
                </td>
              </tr>
              <tr>
                <td style={{verticalAlign: 'top', textAlign: 'right'}}>M2</td>
                <td style={{verticalAlign: "top"}}>:</td>
                <td style={{verticalAlign: "top"}}>
                  <pre style={{fontFamily: "Roboto,sans-serif", fontSize: "inherit", marginBottom: 0}}>{patient.billing_structure_m2}</pre>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}
