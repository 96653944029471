import React from 'react';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import {Button, Col, FormFeedback, FormGroup, FormText, Label, Row, Input} from "reactstrap";
import classNames from 'classnames';
import * as Yup from 'yup';
import validator from 'validator';
import {withRouter} from 'react-router-dom';
import AddressInput from 'components/Address/Input';
import FormatLegacy from "components/Address/FormatLegacy";

const Schema = Yup.object().shape({
  company_name: Yup.string()
    .min(2, 'Too Short!')
    .max(255, 'Too Long!')
    .required('Required'),
  company_address: Yup.string()
    .min(2, 'Too Short!')
    .max(255, 'Too Long!'),
  email: Yup.string()
    .max(255, 'Too Long!'),
  tel: Yup.string()
    .test(
      'tel',
      'Phone number is not valid',
      value => value ? validator.isMobilePhone(value) : true
    )
});

export default withRouter((props) => (
    <Formik
      initialValues={
        {
          company_name: '',
          email: '',
          tel: '',
          ...props.initialValues,
          include_embryo_id: props.initialValues.include_embryo_id,
          include_embryo_grade: props.initialValues.include_embryo_grade,
          include_biopsy_day: props.initialValues.include_biopsy_day,
          show_cycle_number: props.initialValues.show_cycle_number,
          billing_structure_a: props.initialValues.billing_structure_a || '',
          billing_structure_aplus: props.initialValues.billing_structure_aplus || '',
          billing_structure_m: props.initialValues.billing_structure_m || '',
          billing_structure_sr: props.initialValues.billing_structure_sr || '',
          billing_structure_p: props.initialValues.billing_structure_p || '',
          billing_structure_m2: props.initialValues.billing_structure_m2 || '',
          final_report_release_reporting_structure: props.initialValues.final_report_release_reporting_structure || '',
          company_address: props.initialValues.company_address || '{}'
        }
      }
      validationSchema={Schema}
      onSubmit={
        (values, {setSubmitting}) => {
          props.onSubmit(values);
          setSubmitting(false)
        }
      }
    >
      {
        ({errors, touched, handleSubmit, values, isSubmitting, setFieldValue}) => {
          // console.log(
          //   {
          //       'props.initialValues': props.initialValues,
          //       values,
          //       isSubmitting,
          //       errors,
          //       touched
          //   }
          // )
          return (
          <>
            <Form>
              <FormGroup row>
                <Label for="company_name" sm={4}>Clinic Name <span className="text-danger">*</span></Label>
                <Col sm={8}>
                  <Field
                    type="text"
                    name="company_name"
                    data-test-id="clinic-name"
                    className={classNames('form-control', {
                      'is-invalid': touched.company_name && !!errors.company_name
                    })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="company_name" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="email" sm={4}>Email</Label>
                <Col sm={8}>
                  <Field
                    component="textarea"
                    name="email"
                    data-test-id="clinic-email"
                    className={classNames('form-control', {
                      'is-invalid': touched.email && !!errors.email
                    })}
                  />
                  <FormText>Enter email addresses separated by commas (e.g. email1@clinic.com, email2@clinic.com)</FormText>
                  <FormFeedback>
                    <ErrorMessage name="email" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="tel" sm={4}>Phone</Label>
                <Col sm={8}>
                  <Field
                    type="text"
                    name="tel"
                    data-test-id="clinic-phone"
                    className={classNames('form-control', {
                      'is-invalid': touched.tel && !!errors.tel
                    })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="tel" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

              {/*<FormGroup row>*/}
              {/*<Label for="company_address" sm={4}>Address</Label>*/}
              {/*<Col sm={8}>*/}
              {/*<Field*/}
              {/*type="text"*/}
              {/*name="company_address"*/}
              {/*className={classNames('form-control', {*/}
              {/*'is-invalid': touched.company_address && !!errors.company_address*/}
              {/*})}*/}
              {/*/>*/}
              {/*<FormFeedback>*/}
              {/*<ErrorMessage name="company_address" component="span"/>*/}
              {/*</FormFeedback>*/}
              {/*</Col>*/}
              {/*</FormGroup>*/}

              <FormGroup row className="align-items-center">
                <Label for="hide_sex" sm={4}>Sex of Embryo</Label>
                <Col sm={8}>
                  <Field
                    name="hide_sex"
                    component="select"
                    data-test-id="clinic-hide-sex"
                    className="form-control"
                  >
                    <option value={true}>Do Not Report</option>
                    <option value={false}>Report</option>
                  </Field>
                  <FormText>Default value. Can be changed on per case basis</FormText>
                </Col>
              </FormGroup>
              <FormGroup row className="align-items-center">
                <Label for="include_embryo_id" sm={4}>Report Embryo ID</Label>
                <Col sm={8}>
                  <Input
                    name="include_embryo_id"
                    type="select"
                    className="form-control"
                    data-test-id="clinic-report-embryo-ID"
                    value={values.include_embryo_id}
                    onChange={(e) => {setFieldValue('include_embryo_id', e.target.value === 'true')}}
                  >
                    <option value={false}>Do Not Report</option>
                    <option value={true}>Report</option>
                  </Input>
                  <FormText>Default value. Can be changed on per case basis only when "Report" selected.</FormText>
                </Col>
              </FormGroup>
              <FormGroup row className="align-items-center">
                <Label for="include_embryo_grade" sm={4}>Report Embryo Grade</Label>
                <Col sm={8}>
                  <Input
                    name="include_embryo_grade"
                    type="select"
                    data-test-id="clinic-report-embryo-grade"
                    className="form-control"
                    value={values.include_embryo_grade}
                    onChange={(e) => {setFieldValue('include_embryo_grade', e.target.value === 'true' ? true : false)}}
                  >
                    <option value={false}>Do Not Report</option>
                    <option value={true}>Report</option>
                  </Input>
                  <FormText>Default value. Can be changed on per case basis</FormText>
                </Col>
              </FormGroup>
              <FormGroup row className="align-items-center">
                <Label for="include_biopsy_day" sm={4}>Report Biopsy Day</Label>
                <Col sm={8}>
                  <Input
                    name="include_biopsy_day"
                    type="select"
                    className="form-control"
                    data-test-id="clinic-report-biopsy-day"
                    value={values.include_biopsy_day}
                    onChange={(e) => {setFieldValue('include_biopsy_day', e.target.value === 'true' ? true : false)}}
                  >
                    <option value={false}>Do Not Report</option>
                    <option value={true}>Report</option>
                  </Input>
                  <FormText>Default value.</FormText>
                </Col>
              </FormGroup>
              <FormGroup row className="align-items-center">
                <Label for="show_cycle_number" sm={4}>Show Cycle #</Label>
                <Col sm={8}>
                  <Input
                    name="show_cycle_number"
                    type="select"
                    className="form-control"
                    data-test-id="clinic-show-cylce-num"
                    value={values.show_cycle_number}
                    onChange={(e) => {setFieldValue('show_cycle_number', e.target.value === 'true' ? true : false)}}
                  >
                    <option value={false}>Do Not Show</option>
                    <option value={true}>Show</option>
                  </Input>
                  <FormText>Default value. Can be changed on per case basis</FormText>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="billing_structure_a" sm={4}>Billing Structure - PGT-A</Label>
                <Col sm={8}>
                  <Field
                    component='textarea'
                    name="billing_structure_a"
                    data-test-id="clinic-billing-structure-pgta"
                    className={classNames('form-control', {
                      'is-invalid': touched.billing_structure_a && !!errors.billing_structure_a
                    })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="billing_structure_a" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="billing_structure_aplus" sm={4}>Billing Structure - PGT-A+</Label>
                <Col sm={8}>
                  <Field
                    component='textarea'
                    name="billing_structure_aplus"
                    data-test-id="clinic-billing-structure-pgtaplus"
                    className={classNames('form-control', {
                      'is-invalid': touched.billing_structure_aplus && !!errors.billing_structure_aplus
                    })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="billing_structure_aplus" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="billing_structure_m" sm={4}>Billing Structure - PGT-M</Label>
                <Col sm={8}>
                  <Field
                    component='textarea'
                    name="billing_structure_m"
                    data-test-id="clinic-billing-structure-pgtm"
                    className={classNames('form-control', {
                      'is-invalid': touched.billing_structure_m && !!errors.billing_structure_m
                    })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="billing_structure_m" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="billing_structure_sr" sm={4}>Billing Structure - PGT-SR</Label>
                <Col sm={8}>
                  <Field
                    component='textarea'
                    name="billing_structure_sr"
                    data-test-id="clinic-billing-structure-pgtsr"
                    className={classNames('form-control', {
                      'is-invalid': touched.billing_structure_sr && !!errors.billing_structure_sr
                    })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="billing_structure_sr" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="billing_structure_p" sm={4}>Billing Structure - PGT-P</Label>
                <Col sm={8}>
                  <Field
                    component='textarea'
                    name="billing_structure_p"
                    data-test-id="clinic-billing-structure-pgtp"
                    className={classNames('form-control', {
                      'is-invalid': touched.billing_structure_p && !!errors.billing_structure_p
                    })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="billing_structure_p" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="billing_structure_m2" sm={4}>Billing Structure - M2</Label>
                <Col sm={8}>
                  <Field
                    component='textarea'
                    name="billing_structure_m2"
                    data-test-id="clinic-billing-structure-m2"
                    className={classNames('form-control', {
                      'is-invalid': touched.billing_structure_m2 && !!errors.billing_structure_m2
                    })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="billing_structure_m2" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="final_report_release_reporting_structure" sm={4}>Final Report Release Reporting Structure</Label>
                <Col sm={8}>
                  <Field
                    component="textarea"
                    name="final_report_release_reporting_structure"
                    data-test-id="clinic-final-report-structure"
                    className={classNames('form-control', {
                      'is-invalid': touched.final_report_release_reporting_structure && !!errors.final_report_release_reporting_structure
                    })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="final_report_release_reporting_structure" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

            </Form>
            <Row className="align-items-start">
              <Col md={4}>Address</Col>
              <Col md={8}>
                <FormatLegacy value={values.company_address}/>
                <AddressInput
                  onSubmit={(data) => {
                    setFieldValue('company_address', JSON.stringify(data))
                  }}
                  initialValues={
                    values.company_address ? JSON.parse(values.company_address) : null
                  }
                  hide={true}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 30 }} className="justify-content-start">
                <Col md="auto">
                    <Button
                        type="submit"
                        data-test-id="clinic-submit-info"
                        onClick={() => handleSubmit()}
                        disabled={
                            isSubmitting || !!Object.keys(errors).length ||
                            (!Object.keys(props.initialValues).length  && !Object.keys(touched).length)
                        }
                        color="success"
                    >
                        Submit
                    </Button>
                </Col>
                <Col md="auto">
                      <Button color="light" onClick={() => props.onCancel()}>Cancel</Button>
                </Col>
              </Row>
          </>
        )}
      }
    </Formik>
  )
)
