/**
 * Date validator called by other date validator functions
 * Fails if date string format invalid, before year 1900 or after year 2100
 * @param {string} value - date value to validate in form yyyy-mm-dd
 * @returns {object} - {error, year, month, day, Date object} - parsed date.  Error is "" if valid, otherwise contains error message.
 */
export const validateRequiredDate = value => {
  const retVal = {error: null};
  if (value === null || value === undefined || value.trim() === "") return {error: '? incomplete/invalid ?'};
  const m = value.match(/^(\d\d\d\d)-(\d\d)-(\d\d)$/); // yyyy-mm-dd expected
  if (!m) return {error: 'Invalid date'};
  retVal.year = parseInt(m[1], 10);
  retVal.month = parseInt(m[2], 10);
  retVal.day = parseInt(m[3], 10);
  if (retVal.year < 1900 || retVal.year > 2100 || retVal.month < 1 || retVal.month > 12 || retVal.day < 1 || retVal.day > 31) return {error: 'Invalid date'};

  retVal.date = new Date(retVal.year, retVal.month - 1, retVal.day);
  return retVal;
}

/**
 * Date validator called by other date validator functions
 * Fails unless date valid and matches current date, or is in past
 * @param {string} value - date value to validate in form yyyy-mm-dd
 * @returns {object} - {error, year, month, day, Date object} - parsed date.  Error is "" if valid, otherwise contains error message.   */
export const validateRequiredPastDate = value => {
  const valDate = validateRequiredDate(value);
  if (valDate.error) return valDate;

  const currentDate = new Date();
  if (valDate.date > currentDate) return {error: '? Date in future ?'};
  return valDate;
}

/**
 * required-date validator for any date in past in Formik form( validate={validateRequiredRecentPastDate} )
 * Date must be from year 2000 onward, and not be in the future
 * @param {string} value - date value to validate in form yyyy-mm-dd
 * @returns {string} - "" if field is valid; error-string if not
 */
export const validateRequiredRecentPastDate = value => {
  const valDate = validateRequiredPastDate(value);
  if (valDate.error) return valDate.error;

  if (valDate.year < 2000) return '? Date too early ?';
  return "";  // no error
}

/**
 * required-date validator for date-of-birth in Formik form( validate={validateRequiredDOB} )
 * @param {string} value - date value to validate in form yyyy-mm-dd
 * @returns {string} - "" if field is valid; error-string if not
 */
export const validateRequiredDOB = value => {
  const valDate = validateRequiredPastDate(value);
  if (valDate.error) return valDate.error;
  return "";
}

/**
 * required-string validator for individual fields in Formik form( validate={validateRequiredString} )
 * @param value - value to validate
 * @returns {string} - "" if field is valid; error-string if not
 */
export const validateRequiredString = value => {
  if (value === null || value === undefined || value.trim().length === 0) return 'Required';
  return "";
}
