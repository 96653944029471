import React, {useState} from 'react';
import {Button, Classes, Dialog, InputGroup} from "@blueprintjs/core";
import axios from "axios";
import {Link} from "react-router-dom";
import './style/main.css';
import moment from "moment-timezone";
import { isAuthSuperAdminOrReadonly } from "components/RBAC";

const isVisibleForGPOnly = caseStatus => {
    let statuses = ['gc_scheduled', 'embryo_samples_received', 'case_accepted', 'setup_complete', 'report_ready'];
    return statuses.includes(caseStatus)
}

const getToken = () => {
  const data = JSON.parse(window.localStorage.getItem('persist:clinic-portal'));
  const token = JSON.parse(data.auth).token;
  return token ? token : null;
};


export default (props) => {
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [resultCases, setResultCases] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const selectedProviderObj = JSON.parse(window.localStorage.getItem('provider'));
  const selectedProviderId = selectedProviderObj ? selectedProviderObj.id : '';

  const submitSearchRequest = (search) => {
    setLoading(true);

    axios({
      method: 'POST',
      data: {
            name: searchValue,
            provider: selectedProviderId
      },
      responseType: 'json',
      baseURL: process.env.REACT_APP_API_HOST,
      url: `search/`,
      headers: {
        "Authorization": `Token ${getToken()}`
      }
    })
      .then((resp) => {
        if (resp.data) {
          if (resp.data.cases) {
            setResultCases(resp.data.cases);
          }

          setIsOpen(true)
        }
      })
      .catch((e) => {
        // setError('Error. Check the connection')
      })
      .finally(() => {
        setLoading(false);
      });
  }


  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitSearchRequest(searchValue);
        }}
      >
        <InputGroup
          disabled={loading}
          large={false}
          placeholder="Search name or dob..."
          data-test-id="search-field"
          rightElement={<Button type="submit" icon={'search'} style={{borderRadius: 99}} loading={loading} minimal/>}
          small={false}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          type="search"
        />
      </form>

      <Dialog
        icon="search"
        data-test-id="search-icon"
        onClose={() => setIsOpen(false)}
        title="Search Results"
        isOpen={isOpen}
      >
        <div className={Classes.DIALOG_BODY}>
          {
            resultCases.length === 0 && (
              <div>
                <p><strong data-test-id="search-no-result">Cases: No results</strong></p>
              </div>
            )
          }
          {
            resultCases.length > 0 && (
              <div data-test-id="search-result-modal">
                <p><strong data-test-id="search-count">Cases ({resultCases.length}):</strong></p>
                <div className={'table-link table-link-stretch'}>
                  <table
                    style={{borderCollapse: 'collapse', borderSpacing: 0}}
                    cellPadding={0}
                    cellSpacing={0}
                  >
                    {
                      resultCases.map((item, index) => {
                        const linkPath = `/patients/${item.id}/?temp-provider=${item.provider_id}`;

                        return (
                          <tr
                            key={'case-' + index}
                            style={{padding: 0}}
                            data-test-id="search-case-row"
                          >
                            <td>
                              <Link data-test-id="search-case-patient-partner" to={linkPath}>
                                <div>
                                  {item.patient_full_name}{item.partner_full_name && ','}
                                </div>
                                {
                                  item.partner_full_name && (
                                    <div>
                                      {item.partner_full_name}
                                    </div>
                                  )
                                }
                              </Link>
                            </td>

                            <td>
                              <Link data-test-id="search-case-ID" to={linkPath}>
                                {item.case_id ? item.case_id : '-'}
                              </Link>
                            </td>

                            <td>
                              <Link data-test-id="search-case-status" to={linkPath}>
                                {isVisibleForGPOnly(item.case_status) && !isAuthSuperAdminOrReadonly(props.user) ? 'processing' : item.case_status}
                              </Link>
                            </td>

                            <td>
                              <Link data-test-id="search-case-created" to={linkPath}>
                                {moment.utc(item.created_at, 'YYYY-MM-DD hh:mm:ss').local().format('MM/DD/YY')}
                              </Link>
                            </td>
                            {/*<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>*/}
                            {/*  <div style={{width: 120}} className={'bp3-text-overflow-ellipsis'}>*/}
                            {/*    {item.case_id ? item.case_id : '-'}*/}
                            {/*    /!*{item.created_at}{' '}Samples: {item.sample_count}*!/*/}
                            {/*  </div>*/}
                            {/*  <div style={{width: '60%'}}>*/}
                            {/*    {item.patient_full_name}, {item.partner_full_name}*/}
                            {/*  </div>*/}
                            {/*  <div>*/}
                            {/*    {item.case_status}*/}
                            {/*  </div>*/}
                            {/*</div>*/}
                          </tr>
                        )
                      })
                    }
                  </table>
                </div>
              </div>
            )
          }
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button data-test-id="search-result-close-btn" onClick={() => setIsOpen(false)}>Close</Button>
          </div>
        </div>
      </Dialog>
    </div>
  )
}
