import React from "react";
import PaginateButton from "./PaginateButton";
import PaginateGoto from "./PaginateGoto";

const Pagination = ({
  table,
  pageOptions,
  pageRange,
  children,
}) => {
  return (
    <>
      <PaginateButton className='pagingButton' table={table} direction="first" />
      <PaginateButton className='pagingButton' table={table} direction="previous" />

      {/*{pageOptions*/}
      {/*  .slice(*/}
      {/*    Math.max(0, table.getState().pagination.pageIndex - pageRange),*/}
      {/*    table.getState().pagination.pageIndex + pageRange + 1,*/}
      {/*  )*/}
      {/*  .map((pageIndex) => (*/}
      {/*    <PaginateButton*/}
      {/*      key={pageIndex}*/}
      {/*      table={table}*/}
      {/*      direction="specific"*/}
      {/*      page={pageIndex}*/}
      {/*    />*/}
      {/*  ))}*/}

      <PaginateButton table={table} direction="next" />
      <PaginateButton table={table} direction="last" />

      {children && (
        <>
          <span style={{width: "38px"}} />
          {children}
          <span className="w-[1px] bg-gray-600 h-5 self-center"/>
        </>
      )}
    </>
  );
};

Pagination.Goto = PaginateGoto;

export default Pagination;