import React from "react";
import TableHeader from "./TableHeader";
import {createColumnHelper} from "@tanstack/react-table";

/**
 * Convert database sample_type value to capitalized (or ALL CAPS) display value
 * @param st - lowercase value of sample_type from db
 * @returns string - display-value of given sample_type
 */
const displaySampleType = st => {
  if (st === 'dna') return 'DNA';
  if (st === 'other') return st;
  if (st && st.length > 1) return st.charAt(0).toUpperCase() + st.slice(1); // capitalize
  return st;
}

/**
 * Convert database accessioning_type value to capitalized (or ALL CAPS) display value
 * @param at - lowercase value of accessioning_type from db
 * @returns string - display-value of given accessioning_type
 */
const displayAccessioningType = at => {
  if (at === 'qc') return 'QC';
  if (at === 'unprocessed') return at;
  if (at && at.length > 1) return at.charAt(0).toUpperCase() + at.slice(1); // capitalize
  return at;
}

/**
 * dateRangeFilterFn - filter function for date columns
 * @param row - row object being evaluated for filtering (from which .value can be accessed for column)
 * @param columnId - id of column being evaluated for filtering
 * @param filterValue - array of date range to filter within [yyyy-mm-dd from-date string, yyyy-mm-dd to-date string]
 * @returns {boolean} - If true, include the row from display.  If false, exclude row from display.
 */
const dateRangeFilterFn = (row, columnId, filterValue) => {
  const rawRowDateString = row.getValue(columnId);  // string: "mm/dd/yyyy" or '' or null
  if (!rawRowDateString) return (!filterValue[0] && !filterValue[1]);  // allow row with blank date unless a from-date is set

  const [mm, dd, yyyy] = row.getValue(columnId).split('/');
  const rowDateString = `${yyyy}-${mm}-${dd}`;

  // Exclude if date less than from-date
  if (filterValue[0] && rowDateString < filterValue[0]) return false;

  // Exclude if date greater than to-date
  return !(filterValue[1] && rowDateString > filterValue[1]);
}

// remove the filter value from filter state if it is falsy (empty string in this case)
dateRangeFilterFn.autoRemove = val => !val;


// Define the columns to show in the tanstack table
// accessor - columns linked to data model that can be filtered, sorted, etc.
// display - display-only columns not linked to data.  May contain controls.
// grouping - non-data columns used for grouping.  e.g. header, footer ...
const columnHelper = createColumnHelper();
export const columnDefinitions = [
  columnHelper.accessor("id", {
    id: "id",
    header: ({ column, showFeatures }) =>
      <TableHeader title="Accessioning Id" column={column} showFeatures={showFeatures} />,
    cell: (info) => <div style={{textAlign: "center"}}>{info.getValue()}</div>,
    enableSorting: true,
    enableColumnFilter: true,
    filterFn: 'equalsString'  // searches must match exact number
  }),
  columnHelper.accessor("case_id", {
    id: "case_id",
    header: ({ column, showFeatures}) =>
      <TableHeader title="Case Id" column={column} showFeatures={showFeatures} />,
    cell: ({ getValue }) => {
      const v = getValue();
      if (!v) return '';
      const parts = v.split('#'); // values comes from db as:  GPCL-case-id#patient-id#provider-id
      return (<div style={{textAlign: "center"}}><a href={`../patients/${parts[1]}?provider=${parts[2]}`}
                                                    title="Open case in new window"
                                                    target="_blank" rel="noopener noreferrer">
        {parts[0] ? parts[0] : `#${parts[1]}`}
      </a></div>);
        },
        enableSorting: true,
        enableColumnFilter: true,
    filterFn: 'includesString'
  }),
  columnHelper.accessor("clinic", {
    id: "clinic",
    header: ({ column, showFeatures }) =>
      <TableHeader title="Clinic" column={column} showFeatures={showFeatures} />,
    cell: (info) => info.getValue(),
    enableSorting: true,
    enableColumnFilter: true,
    filterFn: 'includesString'  // searches can match any part of clinic name - case-insensitive
  }),
  columnHelper.accessor("sample_type", {
    id: "sample_type",
    header: ({ column, showFeatures }) =>
      <TableHeader title="Sample Type" column={column} showFeatures={showFeatures} />,
    cell: (info) =>
      <div style={{textAlign: "center"}}>
        {displaySampleType(info.getValue())}
      </div>,
    enableSorting: false,
    enableColumnFilter: true,
    meta: {
      filterVariant: 'select_sample_type',
    },
  }),
  columnHelper.accessor("accessioning_type", {
    id: "accessioning_type",
    header: ({ column, showFeatures }) =>
      <TableHeader title="Type" column={column} showFeatures={showFeatures} />,
    cell: (info) =>
      <div style={{textAlign: "center"}}>
        {displayAccessioningType(info.getValue())}
      </div>,
    enableSorting: false,
    enableColumnFilter: true,
    meta: {
      filterVariant: 'select_type',
    },
  }),
  columnHelper.accessor("preamp_date", {
    id: "preamp_date",
    header: ({ column, showFeatures }) =>
      <TableHeader title="PreAmped" column={column} showFeatures={showFeatures} />,
    cell: (info) => <div style={{textAlign: "center"}}>{info.getValue()}</div>,
    enableSorting: true,
    enableColumnFilter: true,
    meta: {
      filterVariant: 'date-range',
    },
    filterFn: dateRangeFilterFn
  }),
  columnHelper.accessor("axiom_date", {
    id: "axiom_date",
    header: ({ column, showFeatures }) =>
      <TableHeader title="Axiomed" column={column} showFeatures={showFeatures} />,
    cell: (info) => <div style={{textAlign: "center"}}>{info.getValue()}</div>,
    enableSorting: true,
    enableColumnFilter: true,
    meta: {
      filterVariant: 'date-range',
    },
    filterFn: dateRangeFilterFn
  }),
  columnHelper.accessor("accessioning_status", {
    id: "accessioning_status",
    header: ({ column, showFeatures }) =>
      <TableHeader title="Status" column={column} showFeatures={showFeatures} />,
    cell: (info) => <div style={{textAlign: "center"}}>{info.getValue()}</div>,
    enableSorting: false,
    enableColumnFilter: true,
    meta: {
      filterVariant: 'select_status',
    },
  }),
  columnHelper.accessor("sample_quantity", {
    id: "sample_quantity",
    header: ({ column, showFeatures }) =>
      <TableHeader title="Samples" column={column} showFeatures={showFeatures} />,
    cell: (info) => <div style={{textAlign: "center"}}>{info.getValue()}</div>,
    enableSorting: false,
    enableColumnFilter: false,
  }),
  columnHelper.display({
    id: "kebab_menu",
    header: () => null,
    cell: (info) => (
      <button className="kebabButton" style={{marginRight:"35px", fontSize: "1.2rem"}}>&nbsp;&#8942;&nbsp;</button>),
    enableSorting: false,
    enableColumnFilter: false,
  }),
];
