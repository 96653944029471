/** All-tab screen for Accessioning Page */
import React, {useState, useMemo, useEffect} from "react";
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel
} from "@tanstack/react-table";
import {columnDefinitions} from "./ColumnDefs";
import '../accessioning.css';

//
// tanstack headless UI components -- see tanstack.com for docs, etc.
//

import Pagination from "./Pagination";

// import {data} from "../../../accession_data";   // Fake data, for now.

import {Card, CardHeader, Col, Container, Row} from "reactstrap";
import {Button, Dialog, InputGroup} from "@blueprintjs/core";
import table from "reactstrap/es/Table";
import NewEmbryonicForm from "./NewEmbryonicForm";
import axios from "axios";


/**
 * Compute the sum of sample-quantity column for currently filtered row-set
 * @param table - tanstack table instance
 * @returns number - sum of sample-quantity column values of currently filtered row-set
 */
const sumSampleQuantity = (table) => {
  const filteredRowModel = table.getFilteredRowModel();
  if (!('rows' in filteredRowModel)) return 0;
  return filteredRowModel.rows.reduce((total, row) => total + row.getValue('sample_quantity'), 0);
}

/**
 * All-Tab screen for Accessioning - shows all Accessioning Batches in all states
 * @param {function} setTotalSampleQuantity - state hook fn to set totalSampleQuantity
 */
export default function AllTab({setTotalSampleQuantity}) {
  const pagination = useMemo(
    () => ({
      pageIndex: 0,
      pageSize: 10,
    }),
    [],
  );

  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState('');  // Value from big search input box at top
  const [sorting, setSorting] = useState([]);  // sorting for each column in tanstack table state
  const [columnFilters, setColumnFilters] = useState([]);  // filtering for each column in tanstack table state
  const [addDropdownOpen, setAddDropdownOpen] = useState(false); // dropdown for +Add button
  const [newEmbryonicModal, setNewEmbryonicModal] = useState(false); // show/hide modal for new embryonic

  // array of which columns are showing their features components (sorting and/or filtering) in header
  const [showColumnFeatures, setShowColumnFeatures] = useState([]);

  useEffect(() => {
    const auth = JSON.parse(window.localStorage.getItem('persist:clinic-portal'));
    const user = auth ? JSON.parse(auth.auth) : null;
    const token = user ? user.token : null;
    if (token) {
      axios ({
        url: 'accessioning/list_for_alltab/',  // url must end in slash
        method: 'GET',
        responseType: 'json',
        baseURL: process.env.REACT_APP_API_HOST,
        headers: {
          "Authorization": `Token ${token}`
        }
      }).then((response) => {
        setData(response.data);
      }).catch((error) => {
        console.error('GET accessioning/list failed', error);
      });
    }
  }, []);

  // tanstack table def
  const tsTable = useReactTable({
    data,
    columns: columnDefinitions,
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      pagination,
    },
    getPaginationRowModel: getPaginationRowModel(),
    filterFns: {},
    state: {
      sorting,
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel()
  });

  // How we set the shared total of all sample quantities
  // https://profy.dev/article/react-useeffect-with-object-dependency
  useEffect(() => {
    setTotalSampleQuantity(sumSampleQuantity(tsTable));
  }, [...Object.values(tsTable)])  // eslint-disable-line

  // Reset all filtering, sorting, et al
  const resetAll = () => {
    tsTable.resetColumnFilters(true);
    tsTable.getColumn('id').toggleSorting(false);
    setShowColumnFeatures([]);  // remove all feature components from column headers
  }

  //
  // Render
  //
  return (
    <>
      {/*New Embryonic Modal dialog*/}
      <Dialog
        icon="plus"
        title="New Embryonic Accessioning"
        isOpen={newEmbryonicModal}
        onClose={() => {
          setNewEmbryonicModal(false);
          window.location.reload();
        }}
        className='accessioning-modal'
      >
        <NewEmbryonicForm onClose={() => {
          setNewEmbryonicModal(false);
          window.location.reload();
        }} />
      </Dialog>

      {!data ? (<div>Loading ...</div>) :
      <Card>

        {/* Header.  Contains Search, reset, +Add */}
        <CardHeader>
          <Container fluid>
            <Row>
              <Col>
                <div style={{display: "inline-block", width: "300px"}}>
                  <InputGroup
                    disabled={false}
                    large={false}
                    placeholder="Search patient name or dob ..."
                    rightElement={<Button type="submit" icon={'search'} style={{borderRadius: 99}} loading={false}
                                          minimal/>}
                    small={false}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    type="search"
                  />
                </div>
                <div className="sortFilterResetButton" title="restore default sorting and remove all filtering"
                     onClick={resetAll}>
                  reset sort/filter
                </div>
              </Col>
              <Col xs lg="2" className="text-right">
                <ButtonDropdown isOpen={addDropdownOpen} toggle={() => setAddDropdownOpen(!addDropdownOpen)}>
                  <DropdownToggle color="primary">
                    +Add
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => setNewEmbryonicModal(true)}>Embryonic</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => window.alert('New Other/DNA has not yet been implemented')}>Other DNA</DropdownItem>
                    <DropdownItem onClick={() => window.alert('New Research/QC has not yet been implemented')}>Research/QC</DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              </Col>
            </Row>
          </Container>
        </CardHeader>

        {/*
          Table of Accessioning Batches
          */}
        <table style={{borderTop: "1px solid lightgray", borderBottom: "1px solid lightgray"}}>
          <thead style={{backgroundColor: '#ebe9f2'}}>
          {tsTable.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className='accessioningTR' style={{height: "50px"}}>
              {headerGroup.headers.map((header) => {
                const headerContext = header.getContext();
                // Inject this into header's context so TableHeader can access it
                headerContext.showFeatures = {
                  showColumnFeatures: showColumnFeatures,
                  setShowColumnFeatures: setShowColumnFeatures,
                };
                return (
                  <th key={header.id} className='accessioningTH' style={header.column.id === 'clinic' ? {textAlign: 'left'} : {}}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        headerContext,
                      )}
                  </th>
                )
              })}
            </tr>
          ))}
          </thead>
          <tbody style={{borderBottom: "1px solid lightgray"}}>
          {tsTable.getRowModel().rows.length === 0 ? (
            <tr>
              <td colSpan={100} style={{padding: "2rem", textAlign: "center"}}><i>No matching entries found</i></td>
            </tr>
          ) : (
            tsTable.getRowModel().rows.map((row) => (
            <tr key={row.id} className='accessioningTR'>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} style={{height: "50px"}}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          )))}
          </tbody>
        </table>

        {/*Row of paging controls beneath accessioning table*/}
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'right',
          height: '50px',
          backgroundColor: '#ebe9f2'
        }}>
          <Pagination
              table={tsTable}
              pageRange={3}
              pageOptions={tsTable.getPageOptions()}
          >
            <Pagination.Goto table={tsTable} options={[10, 20, 30, 40, 50]}/>
          </Pagination>
        </div>

      </Card>}
    </>
  );
};
