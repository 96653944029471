import React, {useState, useRef, useEffect} from "react";
import {Button, Col, Row} from "reactstrap";
import ClinicDropdown from "./ClinicDropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Formik, Form, Field, ErrorMessage, FieldArray} from "formik";
import {Dialog} from "@blueprintjs/core";
import axios from "axios";
import {NumericField} from "../../../utils/Formik2/NumericField";
import {
  validateRequiredDOB,
  validateRequiredRecentPastDate,
  validateRequiredString
} from "../../../utils/Formik2/validators";

//
// A note on variable case [Seth]
// camelCase is standard for variable naming (e.g. filesToUpload), but
// when a variable is used to holds data to/from db tables, it will be named in snake_case instead (e.g. embryo_number)
// to minimize errors/confusion.

export default function NewEmbryonicForm({onClose}) {
  const [clinic, setClinic] = useState(null);   // {id: provider id, company_name: clinic name}

  const [errorAlertModal, setErrorAlertModal] = useState(false); // show/hide error modal
  const [alertMessage, setAlertMessage] = useState(""); // message for validate or alert modals

  const [displayMatchedCaseId, setDisplayMatchedCaseId] = useState("");
  const [matchingCaseUrl, setMatchingCaseUrl] = useState("");
  const [dupGPCL, setDupGPCL] = useState(""); // if set, is duplicate GPCL ID found for another case

  // Array of files that have been uploaded.  Each array element is object that consists of:
  //      file_name - e.g. 'important-report.pdf'
  //      url - this is file's address/nametag within S3.  Use this in browser to access file directly.
  //      ext - file's extension/type.  E.g. pdf, jpg, doc, xlsx ...
  //      datetime - datetime file was uploaded, in iso format.  E.g. '2024-10-14 09:50 PM'
  const [filesUploaded, setFilesUploaded] = useState([]);

  const [isSavingDraft, setIsSavingDraft] = useState(false);  // is saveDraft in progress?
  const [displayDraftSavedMessage, setDisplayDraftSavedMessage] = useState(false);
  const [draftData, setDraftData] = useState(null); // mix of draft db and formatted dialog data

  const [formikInitialValues, setFormikInitialValues] = useState({
    received_at: null,
    patient_first_name: "",
    patient_last_name: "",
    patient_dob: null,
    embryo_samples: []
  });

  // form doesn't display until setupComplete.
  // setup not complete until draft evaluated/loaded/discarded, if applicable
  // or, if editing, accessioning batch values loaded.
  const [setupComplete, setSetupComplete] = useState(false);
  const [filesUploading, setFilesUploading] = useState(false); // needed to avoid double-processing

  const hiddenInputFileButtonRef = useRef(null); // ref to hidden <input type='file' ...
  const draftDialogRef = useRef(null);  // ref to draft dialog
  const resolveDraftDialogRef = useRef(null);  // ref to resolve draft dialog promise
  const linkDialogRef = useRef(null);  // ref to link dialog
  const resolveLinkDialogRef = useRef(null);  // ref to resolve link dialog promise

  // Acceptable extensions for file uploads
  const acceptedFileExtensions = ["pdf","png","jpg","jpeg","xls","xlsx","txt","rtf","doc","docx"];
  const acceptedFileTypeRegex = new RegExp(acceptedFileExtensions.join("|"), "i");
  const acceptedFileTypesString = acceptedFileExtensions.map((ext) => `.${ext}`).join(",") +
                                            ",application/vnd.openxmlformats-officedocument.wordprocessingml.document"

  const CLINIC_SSF = 'samples_form'; // field in Patient table/form where uploaded files enumerated


  // First, try to retrieve draft for previous Accessioning Batch session
  useEffect(() => {
    const token = getAuthToken();
    if (token) {
      axios(axiosParams('accessioning-drafts', token, null, 'GET')).then((response) => {
        if (response.data) {
          // User has a prior draft.  Show it to them and give them choices.
          const snapshotData = JSON.parse(response.data.snapshot);
          const formValues = snapshotData.formValues;
          setDraftData({
            datetime: response.data.created_at,
            clinicId: snapshotData.clinic_id,
            clinicName: response.data.clinic_name ? response.data.clinic_name : '(no clinic chosen)',
            formValues: formValues,
            filesUploaded: snapshotData.filesUploaded,
            patientFirstName: formValues.patient_first_name ? formValues.patient_first_name : '(no first name)',
            patientLastName: formValues.patient_last_name ? formValues.patient_last_name : '(no last name)',
            patientDOB: formValues.patient_dob ? formValues.patient_dob : '',
            sampleCount: formValues.embryo_samples ? formValues.embryo_samples.length : 0,
          });  // triggers next useEffect - processing continues there ...
        } else
          setSetupComplete(true);
      }).catch((error) => {
        alertAndFail(`Unable to retrieve your last draft session - ${error}`);
        setSetupComplete(true);  // Set setupComplete to true even if there's an error
      })
    }
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps


  // when a draft has been retrieved for this user...
  useEffect(() => {
    if (draftData) {
      showModalDraftDialog().then(draftResponse => {
        if (draftResponse === 'RESUME') {
          loadDraft();
        } else if (draftResponse === 'DISCARD') {
          discardDraft();
          setSetupComplete(true);
        } else {  // draftResponse is 'QUIT'
          onClose();
        }
      })
    }
  }, [draftData]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * loadDraft.  User has chosen to resume their previous work draft.  Load data and trigger display.
   */
  const loadDraft = () => {
    setClinic({id: draftData.clinicId, company_name: draftData.clinicName});
    setFilesUploaded(draftData.filesUploaded);
    setFormikInitialValues(draftData.formValues);
    setSetupComplete(true);
  };

  /**
   * discardDraft.  User has chosen to discard their previous work.  Remove any uploaded files, then draft record.
   */
  const discardDraft = () => {
    const token = getAuthToken();
    if (token) {
      draftData.filesUploaded.forEach((fileUploadedItem) =>
        axios(axiosParams('file/0', token, {
              type: CLINIC_SSF,
              file: JSON.stringify(fileUploadedItem),  // item, as returned when uploaded
              accessioning: 'standalone'
            },
            'DELETE'
          )
        )
      );

      deleteDraft();
    }
  }

  const isoUTCtoLocalDateTime = isoString => {
    const date = new Date(isoString);

    const options = {
      weekday: 'long',   // Day of the week
      year: 'numeric',   // Full year
      month: 'long',     // Full month name
      day: 'numeric',    // Day of the month
      hour: 'numeric',   // Hours
      minute: 'numeric', // Minutes
      hour12: true       // Use 12-hour AM/PM format
    };

    return date.toLocaleDateString(undefined, options);
  };

  const displayCount = (count, outputWord) => {
    if (count === undefined || count === null || count === 0) return `no ${outputWord}s`;
    if (count === 1) return `1 ${outputWord}`;
    return `${count} ${outputWord}s`;
  }


  /**
   * get user's auth token which is needed for API calls.
   * @param {boolean} silent - display alert if unable to get token?  False, display alert.  True, remain silent.
   * @returns {string|false} - returns token string if ok.  If not, displays error dialog to user and returns null.
   */
  const getAuthToken = (silent=false) => {
    const auth = JSON.parse(window.localStorage.getItem('persist:clinic-portal'));
    const user = auth ? JSON.parse(auth.auth) : null;
    const token = user ? user.token : null;
    if (!auth || !user || !token) {
      if (!silent) return alertAndFail(`Authorization lost.  Try logging back in.`);
      return false;
    }
    return token;
  }

  /**
   * Shortcut to make parameters for axios for backend calls
   * @param {string} url - url to invoke.  Trailing slash added if not included.
   * @param {string} token - token returned from getAuthToken
   * @param {object|null} data - data, if applicable
   * @param {string} method - http method - 'GET', 'POST' or other.  'POST' is default.
   * @returns {object} - parameter object to use in axios call
   */
  const axiosParams = (url, token, data=null, method = 'POST') => {
    return {
      url: url.charAt(url.length - 1) !== '/' ? url + '/' : url,  // url must end in slash
      method: method,
      responseType: 'json',
      baseURL: process.env.REACT_APP_API_HOST,
      headers: {
        "Authorization": `Token ${token}`
      },
      data: data
    }
  }

  /**
   * Save current form data (clinic, values, files, etc.) to accessioning_batch_draft table for current user
   * so user can resume input the next time they bring up this screen.
   * Called when a file is uploaded/deleted or when user clicks Save Draft.
   * @param {object} values - Formik values
   * @param {object[]|null} newFilesUploaded - new value for filesUploaded.  Needed because saveDraft called
   *                                           within setState functions when files added/deleted.
   */
  const saveDraft = (values, newFilesUploaded = null) => {
    if (!isSavingDraft) {
      setIsSavingDraft(true);
      const token = getAuthToken();
      if (token) {
        const snapshotData = {
          clinic_id: clinic ? clinic.id : null,
          formValues: values,
          filesUploaded: newFilesUploaded !== null ? [...newFilesUploaded] : [...filesUploaded]
        };
        axios(axiosParams('accessioning-drafts', token, {snapshot: JSON.stringify(snapshotData)})).then((response) => {
          setIsSavingDraft(false);
          setDisplayDraftSavedMessage(true);
          setTimeout(() => setDisplayDraftSavedMessage(false), 3000);
        }).catch((error) => {
          setIsSavingDraft(false);
          alertAndFail(`Unable to save your draft session - ${error}`);
        })
      }
    }
  };

  const deleteDraft = () => {
    const token = getAuthToken();
    if (token) {
      axios(axiosParams('accessioning-drafts', token, null, 'DELETE')).then((response) => {
        // Do nothing
      }).catch((error) => {
        alertAndFail(`? Unable to delete your draft session ? - ${error} - please notify IT department`);
      })
    }
  };

  const viewFile = url => {
    const token = getAuthToken();
    if (token) {
      axios.post('/api/get-uploaded-files-presigned-url', {
        file_name: url
      }, {
        headers: {
          "Authorization": `Token ${token}`
        },
      }).then((res) => {
        window.open(res.data.presigned_url, '_blank')
      })
    }
  };

  /**
   * Add file(s) that user chose (with upload button or Drag&Drop) to the upload-files list
   * @param filesToUpload - array of files to add to upload-files list
   * @param values - Formik values object
   */
  const uploadTheseFiles = (filesToUpload, values) => {
    if (!filesUploading) {
      setFilesUploading(true);
      filesToUpload.forEach((fileToUpload) => {
        if (filesUploaded.some((fileAlreadyUploaded) => fileAlreadyUploaded.name === fileToUpload.name)) {
          // Do nothing if file already uploaded
          setFilesUploading(false);
        } else if (!acceptedFileTypeRegex.test(fileToUpload.name.split(".").pop())) {
          setFilesUploading(false);
          alertAndFail(`file(s) skipped - only ${acceptedFileExtensions.join(", ")} files allowed`);
        } else {
          // read file data
          const reader = new FileReader();

          reader.onloadend = () => {
            // file read successfully, upload it
            const token = getAuthToken();
            if (token) {
              axios(axiosParams('file/0', token, {
                file_name: fileToUpload.name,
                type: CLINIC_SSF,
                file: reader.result,  // file contents
                accessioning: 'standalone'
              })).then((response) => {
                setFilesUploaded(curFilesUploaded => {
                  const newFilesUploaded = [...curFilesUploaded, response.data.new_item];
                  saveDraft(values, newFilesUploaded);
                  return newFilesUploaded;
                });
                setFilesUploading(false);
              }).catch((error) => {
                setFilesUploading(false);
                alertAndFail(`Unable to upload ${fileToUpload.name} - ${error}`);
              })
            }
          };

          reader.readAsDataURL(fileToUpload);
        }
      })
    }
  }

  /**
   * Remove an uploaded file from S3 and upload-files array (after user hits delete (X) button)
   * @param {number} index - index of element to remove
   * @param values - Formik values object
   */
  const deleteUploadedFile = (index, values) => {
    const fileUploadedItem = filesUploaded[index];
    const token = getAuthToken();
    if (token) {
      axios(axiosParams('file/0', token, {
        type: CLINIC_SSF,
        file: JSON.stringify(fileUploadedItem),  // item, as returned when uploaded
        accessioning: 'standalone'
      }, 'DELETE')).then((response) => {
        setFilesUploaded(curFilesUploaded => {
          const newFilesUploaded = [...curFilesUploaded];
          newFilesUploaded.splice(index, 1);
          saveDraft(values, newFilesUploaded);
          return newFilesUploaded;
        });
      }).catch((error) => {
        alertAndFail(`Unable to delete ${fileUploadedItem.file_name} - ${error}`);
      })
    }
  };


  /**
   * Display error alert Dialog with given message.
   * @param {string} message - message to display
   * @returns {boolean} - always false, as a convenience for additionalValidation
   */
  const alertAndFail = message => {
    setAlertMessage(message);
    setErrorAlertModal(true);
    return false;
  }

  /**
   * Additional validation steps to take before saving accessioning batch.
   * If any step fails validation, display an alert dialog
   * @param {object} values
   * @returns {boolean} - true if valid for submission; false if not
   */
  const additionalValidation = (values) => {
    // clinic dropdown not within Formik, so have to verify separately
    if (!clinic || !clinic.id) return alertAndFail("Choosing a Clinic is Required");

    // fail if user didn't add any samples
    const sampleArray = values.embryo_samples;
    if (sampleArray.length === 0) return alertAndFail("? Batch contains no samples ?");

    // check for duplicate embryo numbers or tube labels
    const embryo_numberSet = new Set();
    const tube_labelSet = new Set();
    for (let index = 0; index < sampleArray.length; index++) {
      const embryo_number = sampleArray[index].embryo_number;
      if (embryo_numberSet.has(embryo_number))
        return alertAndFail(`Sample ${index+1} duplicates Embryo # ${embryo_number}`);
      embryo_numberSet.add(embryo_number);

      const tube_label = sampleArray[index].tube_label;
      if (tube_labelSet.has(tube_label))
        return alertAndFail(`Sample ${index+1} duplicates Tube Label ${tube_label}`);
      tube_labelSet.add(tube_label);
    }

    // passed all additional validations
    return true;
  }

  /**
   * Present a RESUME/DISCARD/QUIT dialog to user when they have a prior session (saved in draft).
   * @returns {Promise} - resolves to user's choice 'RESUME', 'DISCARD' or 'QUIT'
   */
  const showModalDraftDialog = () => {
    draftDialogRef.current.showModal();

    return new Promise((resolve) => {
      resolveDraftDialogRef.current = resolve;     // Store promise's resolve function
    });
  };

  /**
   * Present a YES/NO/CANCEL dialog to user when there is a potential match-up of batch to a case
   * @returns {Promise} - resolves to user's choice 'YES', 'NO' or 'CANCEL'
   */
  const showModalLinkDialog = () => {
    linkDialogRef.current.showModal();

    return new Promise((resolve) => {
      resolveLinkDialogRef.current = resolve;     // Store promise's resolve function
    });
  };

  // When submitting, user has opted to link matching case to this Accessioning.
  // Set case id, samples and SSFs, then save accessioning batch
  const linkCaseToAccessioningAndSave = (patient_id, token, values, setSubmitting) => {
    axios(axiosParams(`patients/${patient_id}`, token, null, 'GET')).then(caseResponse => {
      const data = caseResponse.data;

      // If case not yet assigned an ID, get GPCL ID from first tube-label if it's in form #####-## (or similar),
      // in which case GPCL ID is the first block of digits (e.g. 12312-01, GPCL ID is 12312)
      if (!data.case_id) {
        const gpclIdMatch = values.embryo_samples[0].tube_label.match(/^(\d+)-(\d+)$/);
        if (gpclIdMatch) data.case_id = gpclIdMatch[1];
      }

      data.samples = [...data.samples];
      // Add Accessioning Samples to case's samples
      values.embryo_samples.forEach(sample => {
        data.samples.push({
          patient_id,
          provider_id: values.provider_id,
          tube_label: sample.tube_label,
          embryo_number: sample.embryo_number,
          embryo_id: sample.embryo_id,
          tissue_type: sample.tissue_type,
          qc_check: sample.QC ? 'pass' : 'fail',
          notes: sample.note,
          created_at: sample.created_at,
          updated_at: new Date().toISOString(),
          icsi: data.icsi,
          conventional_ivf: data.conventional_ivf
        })
      });

      // Add Uploaded files to case's files
      if (filesUploaded.length > 0) {
        const items = data.samples_form ? JSON.parse(data.samples_form) : [];
        data.samples_form = JSON.stringify([...items, ...filesUploaded]);
      }

      // save accessioning info to case
      axios(axiosParams(`patients/${patient_id}`, token, data, 'PATCH')).then(updateResponse => {
        // accessioning data saved to case.  Now, finally, save the Accessioning Batch, samples etc.
        values.patient_id = patient_id;
        saveAccessioningBatch(token, values, setSubmitting);
      })
      .catch(error => {
        alertAndFail('Submit failed.  Please notify IT department.');
        console.error('submit failed', error.detail);
      });
    })
  }

  // During submit, a matched case was found
  // Ask user if they want to link this Accessioning to it, then proceed based on their response
  const askLinkSave = (matchFoundResponse, values, token, setSubmitting) => {
    if (matchFoundResponse.data.status === 'duplicate_gpcl') {
      const gpclIdMatch = values.embryo_samples[0].tube_label.match(/^(\d+)-(\d+)$/);
      if (gpclIdMatch)
        setDupGPCL(gpclIdMatch[1]);
      else
        setDupGPCL('');
    } else
        setDupGPCL('');
    setDisplayMatchedCaseId(matchFoundResponse.data.case_id ? matchFoundResponse.data.case_id : `#${matchFoundResponse.data.id}`);
    setMatchingCaseUrl(`../patients/${matchFoundResponse.data.id}?provider=${values.provider_id}`);
    showModalLinkDialog().then(linkUserResponse => {
      if (linkUserResponse === 'YES') {
        linkCaseToAccessioningAndSave(matchFoundResponse.data.id, token, values, setSubmitting)
      }
      else if (linkUserResponse === 'NO') {
        // just save the accessioning.  Leave the matching case alone.
        saveAccessioningBatch(token, values, setSubmitting);
      }
      else {  // linkUserResponse is 'CANCEL'
        setSubmitting(false);
      }
    })
  };

  /**
   * Start the submit process when user clicks the Submit button within Formik form.
   * @param {Object} values - Formik values
   * @param {function} setSubmitting - set false to tell Formik when submit process is over (good or bad)
   */
  const doSubmit = (values, setSubmitting) => {
    // check for duplicates and other non-Formik validation
    if (!additionalValidation(values)) {
      setSubmitting(false);
      return;
    }

    // complete rest of values
    values.provider_id = clinic.id;
    values.accessioning_type = 'clinic';
    values.sample_type = 'embryonic';
    values.patient_id = null;  // no case match
    values.live_flag = 1;  // 1 = live, 0 = draft, -1 = deleted
    values.embryo_samples.forEach((embryo_sample) => {
      embryo_sample.accessioning_status = 'accessioned';
      embryo_sample.QC = (embryo_sample.QC === 'pass');  // convert to boolean
    });
    values.files = [...filesUploaded];

    const token = getAuthToken();
    if (!token) {
      setSubmitting(false);
      return;
    }

    // Does this new batch match-up to an existing Case?
    axios(axiosParams('patients/get_accessioning_match', token, values)).then(matchResponse => {
      if (matchResponse.data && matchResponse.data.status !== 'none') {
        if (matchResponse.data.status === 'error') {
          setSubmitting(false);
          alertAndFail('Match-up failed.  Please notify IT department.');
        } else {  // 'matched' or 'duplicate_gpcl'
          askLinkSave(matchResponse, values, token, setSubmitting);
        }
      } else { // batch matches no extant cases - continue with submit
        saveAccessioningBatch(token, values, setSubmitting);
      }
    }).catch(error => {
      setSubmitting(false);
      alertAndFail('Match-up failed.  Please notify IT department.');
      console.error('match-up failed', error);
    })
  }

  // Save all accessioning data on this screen to db
  const saveAccessioningBatch = (token, values, setSubmitting) => {
    axios(axiosParams('accessioning', token, values)).then(response => {
      deleteDraft();
      onClose();
    })
    .catch(error => {
      alertAndFail('Submit failed.  Please notify IT department.');
      console.error('submit failed', error);
    })
    .finally(() => {
      setSubmitting(false); // Stop the submission state in Formik
    });
  }

  //
  // Render
  //
  return (
    <>
      {/* Dialog Displayed when additional validation finds an issue, or other error */}
      <Dialog title="Alert" isOpen={errorAlertModal} className='accessioning-error-alert-modal'
        onClose={() => setErrorAlertModal(false)}
      >
        <div className='m-3'>{alertMessage}</div>
      </Dialog>

      {/* Dialog Displayed initially when user has a previous draft -- Resume (load draft), Discard (delete draft) or Quit */}
      <dialog ref={draftDialogRef} className='accessioning-success-alert-modal' style={{width: '640px'}}>
        {draftData ? (
          <>
          <div className="accessioning-choice-modal text-right" onClick={onClose}>x</div>
          <div className="mt-3 mb-2 text-center">You have a draft saved <b>{isoUTCtoLocalDateTime(draftData.datetime)}</b> for:</div>
          <div className="text-center">{draftData.clinicName}</div>
          <div className="text-center">{draftData.patientFirstName} {draftData.patientLastName}
            {draftData.patientDOB ? ` b. ${draftData.patientDOB}` : ''}</div>
          <div className="text-center">
            {displayCount(draftData.sampleCount, 'sample')} -{' '}
            {`${displayCount(draftData.filesUploaded.length, 'file')} uploaded`}
          </div>
          <div className='m-3 d-flex justify-content-center'>
            <table style={{ border: 0, borderCollapse: 'separate', borderSpacing: '10px 25px' }}>
              <tbody>
              <tr className='accessioning-choice-modal' onClick={() => {
                      draftDialogRef.current.close();
                      if (resolveDraftDialogRef.current) resolveDraftDialogRef.current('RESUME');
                    }}
              >
                <td style={{ marginBottom: '15px' }}>
                  <Button color="success" onClick={() => {
                      draftDialogRef.current.close();
                      if (resolveDraftDialogRef.current) resolveDraftDialogRef.current('RESUME');
                    }}
                  >
                    RESUME
                  </Button>
                </td>
                <td className='text-left' style={{ marginBottom: '15px' }}>
                  Load this draft and Resume working on it
                </td>
              </tr>
              <tr className='accessioning-choice-modal' onClick={() => {
                      draftDialogRef.current.close();
                      if (resolveDraftDialogRef.current) resolveDraftDialogRef.current('DISCARD');
                    }}
              >
                <td style={{ marginBottom: '15px' }}>
                  <Button color="info" onClick={() => {
                      draftDialogRef.current.close();
                      if (resolveDraftDialogRef.current) resolveDraftDialogRef.current('DISCARD');
                    }}
                  >
                    DISCARD
                  </Button>
                </td>
                <td className='text-left' style={{ marginBottom: '15px' }}>
                  Discard this draft.<br/>Values, samples and all uploaded files removed.
                </td>
              </tr>
              <tr className='accessioning-choice-modal' onClick={() => {
                    draftDialogRef.current.close();
                    if (resolveDraftDialogRef.current) resolveDraftDialogRef.current('QUIT');
                  }}
              >
                <td>
                  <Button color="warning" className='mr-2' onClick={() => {
                    draftDialogRef.current.close();
                    if (resolveDraftDialogRef.current) resolveDraftDialogRef.current('QUIT');
                  }}
                  >
                    QUIT
                  </Button>
                </td>
                <td className='text-left'>
                  Do nothing (exit)
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          </>) : ''}
      </dialog>

      {/* Displayed after user clicks Submit when new batch matches up to existing case */}
      <dialog ref={linkDialogRef} className='accessioning-success-alert-modal' style={{width:'640px'}}>
        <div className='m-3'>
          Clinic and Patient match existing Case <a href={matchingCaseUrl} target="_blank" title='Open case in new window'
                                              rel="noopener noreferrer">{displayMatchedCaseId}</a><br />
          Link Accessioning to this case when saving ?<br /><br />
          {dupGPCL ? `>> Cannot Link - GPCL ID ${dupGPCL} already in use by another case <<`: ''}
        </div>
        <Row className='mt-4 mr-0'>
          <Col md={4}>
            &nbsp;
          </Col>
          <Col md={4}>
            <Button autoFocus disabled={dupGPCL > ""} className='mr-2' onClick={() => {
              linkDialogRef.current.close();
              if (resolveLinkDialogRef.current) resolveLinkDialogRef.current('YES');
             }}
            >
              YES
            </Button>
            <Button color="info" onClick={() => {
              linkDialogRef.current.close();
              if (resolveLinkDialogRef.current) resolveLinkDialogRef.current('NO');
            }}>
              NO
            </Button>
          </Col>
          <Col md={4} className='ml-auto'>
            <Button color="warning" className='mr-2' onClick={() => {
              linkDialogRef.current.close();
              if (resolveLinkDialogRef.current) resolveLinkDialogRef.current('CANCEL');
            }}>
              CANCEL
            </Button>
          </Col>
        </Row>
      </dialog>

      {setupComplete ? (
        <Formik initialValues={formikInitialValues}
          onSubmit={(values, {setSubmitting}) => doSubmit(values, setSubmitting)}
        >
          {
            ({values, isSubmitting}) => {
              return (
                <Form autoComplete="off">
                  <Row className="mt-2">
                    {/*
                        Clinic input dropdown
                    */}
                    <Col md={{size: 7, offset: 1}} className='p-1'>
                      <div>Clinic</div>
                      <div>
                        <ClinicDropdown clinic={clinic} setClinic={setClinic}/>
                      </div>
                    </Col>
                    {/* Sample Received Date */}
                    <Col md={3} className='p-1'>
                      <div>Sample Received</div>
                      <div><Field name="received_at" type="date" validate={validateRequiredRecentPastDate} /></div>
                      <ErrorMessage name="received_at">
                        {msg => <div className='text-danger'>{msg}</div>}
                      </ErrorMessage>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    {/*
                        Patient name, DOB
                    */}
                    <Col md={{size: 7, offset: 1}} className='p-1'>
                      <Row className='w-100'>
                        <Col md={6}>
                          <div>Patient</div>
                          <div><Field name="patient_first_name" type="text" className='w-100' validate={validateRequiredString}/></div>
                          <small>first name</small>
                          <ErrorMessage name="patient_first_name">
                            {msg => <div className='text-danger'>{msg}</div>}
                          </ErrorMessage>
                        </Col>
                        <Col md={6}>
                          <div>&nbsp;</div>
                          <div><Field name="patient_last_name" type="text" className='w-100' validate={validateRequiredString}/></div>
                          <small>last name</small>
                          <ErrorMessage name="patient_last_name">
                            {msg => <div className='text-danger'>{msg}</div>}
                          </ErrorMessage>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={3} className='p-1'>
                      <div>DOB</div>
                      <div><Field name="patient_dob" type="date" validate={validateRequiredDOB}/></div>
                      <ErrorMessage name="patient_dob">
                        {msg => <div className='text-danger'>{msg}</div>}
                      </ErrorMessage>
                    </Col>
                  </Row>
                  {/*
                      File Uploads
                  */}
                  <Row className="mt-2 w-100">
                    <Col md={{size: 12, offset: 1}} className='p-1'>
                      <div><h5>SSF or Accessioning Photo Files</h5></div>
                      {/* Show files Uploaded. */}
                      {filesUploaded.length === 0 ? <div><i>No files uploaded</i></div> : ''}
                      {filesUploaded.map((file, index) => (
                        <div style={{fontSize: '80%', fontWeight: 'bold'}}>
                          <span onClick={() => {viewFile(file.url)}} style={{cursor: "pointer"}}>{file.file_name}</span>
                          {/* Delete X Button */}
                          <Button color="link" size="sm" onClick={event => {
                            event.stopPropagation();
                            deleteUploadedFile(index, values);
                          }}>
                            <span className="text-danger ml-2" title="Remove this uploaded file">
                              <FontAwesomeIcon icon="times"/>
                            </span>
                          </Button>
                        </div>
                      ))}
                      {/*Dotted zone to drag/upload files*/}
                      <div className='mt-2 w-75 rounded-lg justify-content-center align-items-center text-center'
                           style={{border: '2px dotted gray', backgroundColor: '#e2e9ec', height: '12vh'}}
                           onDragOver={(e) => e.preventDefault()}
                           onDrop={(event) => {
                             event.preventDefault();
                             uploadTheseFiles(Array.from(event.dataTransfer.files), values);
                           }}
                      >
                        <div className='mt-2'>Drag &amp; Drop SSF, photo or other relevant files here</div>
                        <div>or</div>
                        <Button color="primary" className='mt-1' onClick={() => hiddenInputFileButtonRef.current.click()}>Browse Files from your Computer</Button>
                        <input
                          type="file"
                          id="hiddenInputFileButton"
                          name="hiddenInputFileButton"
                          multiple
                          accept={acceptedFileTypesString}
                          ref={hiddenInputFileButtonRef}
                          className="hidden"
                          onChange={(event) => {
                            uploadTheseFiles(Array.from(event.target.files), values);
                          }}
                          onClick={(event) => {
                            event.target.value = null;  // to allow selecting again
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <hr className="w-100"/>
                  </Row>
                  {/*
                      Embryo Samples Table
                  */}
                  <FieldArray name="embryo_samples">
                    { ({ push, remove }) => (
                      <>
                      <Row className="mt-2">
                        <Col md={{size: 1, offset: 1}} className='p-1'>
                          <h5>Samples</h5>
                        </Col>
                        <Col>
                          <Button color="primary" size='sm' className='ml-2'
                                  onClick={() => push({
                                    embryo_number: "",
                                    tube_label: "",
                                    tissue_type: "",
                                    issue: "",
                                    QC: "pass",
                                    on_hold: false,
                                    note: ""
                                  })}
                          >
                            [+]
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={{size: 10, offset: 1}} className='p-1'>
                          <table className='w-100 mb-3 table-bordered'>
                            <thead>
                            <tr>
                              <th>&nbsp;</th>
                              <th style={{width: '10%'}}>Embryo #</th>
                              <th style={{width: '15%'}}>Tube Label</th>
                              <th style={{width: '15%'}}>Sample Type</th>
                              <th style={{width: '20%'}}>Issue</th>
                              <th style={{width: '10%'}}>QC</th>
                              <th style={{width: '5%'}}>Hold</th>
                              <th style={{width: '35%'}}>Note</th>
                              <th>&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody>
                            {values.embryo_samples && values.embryo_samples.length > 0 && values.embryo_samples.map((sample, index) => (
                              <tr key={index}>
                                <td className='text-center'>{index + 1}</td>
                                <td>
                                  <Field
                                    name={`embryo_samples[${index}].embryo_number`}
                                    className='border-0 w-100 text-center'
                                    component={NumericField}
                                    validate={validateRequiredString}
                                  />
                                  <ErrorMessage name={`embryo_samples[${index}].embryo_number`}>
                                    {msg => <div className='text-danger'>{msg}</div>}
                                  </ErrorMessage>
                                </td>
                                <td>
                                  <Field
                                    name={`embryo_samples[${index}].tube_label`}
                                    className='border-0 w-100 text-center'
                                    validate={validateRequiredString}
                                  />
                                  <ErrorMessage name={`embryo_samples[${index}].tube_label`}>
                                    {msg => <div className='text-danger'>{msg}</div>}
                                  </ErrorMessage>
                                </td>
                                <td>
                                  <Field
                                    name={`embryo_samples[${index}].tissue_type`}
                                    component="select"
                                    className='border-0 w-100'
                                    validate={validateRequiredString}
                                  >
                                    <option value=""></option>
                                    <option value="trophectoderm">Trophectoderm</option>
                                    <option value="arrested">Whole Embryo</option>
                                  </Field>
                                  <ErrorMessage name={`embryo_samples[${index}].tissue_type`}>
                                    {msg => <div className='text-danger'>{msg}</div>}
                                  </ErrorMessage>
                                </td>
                                <td>
                                  <Field
                                    name={`embryo_samples[${index}].issue`}
                                    component="select"
                                    className='border-0 w-100'
                                  >
                                    <option value=""></option>
                                    <option value="stickerMissing">Sticker Missing</option>
                                    <option value="tubeMismatch">Tube-Label Mismatch</option>
                                    <option value="other">other</option>
                                  </Field>
                                  <ErrorMessage name={`embryo_samples[${index}].issue`}>
                                    {msg => <div className='text-danger'>{msg}</div>}
                                  </ErrorMessage>
                                </td>
                                <td>
                                  <Field
                                    name={`embryo_samples[${index}].QC`}
                                    component="select"
                                    className='border-0 w-100'
                                  >
                                    <option value="pass">Pass</option>
                                    <option value="fail">FAIL</option>
                                  </Field>
                                  <ErrorMessage name={`embryo_samples[${index}].QC`}>
                                    {msg => <div className='text-danger'>{msg}</div>}
                                  </ErrorMessage>
                                </td>
                                <td className='text-center'>
                                  <Field
                                    name={`embryo_samples[${index}].on_hold`}
                                    type="checkbox"
                                    className='border-0'
                                  />
                                </td>
                                <td>
                                  <Field name={`embryo_samples[${index}].note`} className='border-0 w-100' type='text'/>
                                  <ErrorMessage name={`embryo_samples[${index}].note`}>
                                    {msg => <div className='text-danger'>{msg}</div>}
                                  </ErrorMessage>
                                </td>
                                <td onClick={() => remove(index)}>
                                  <span className="text-danger ml-2" title="Delete this sample">
                                    <FontAwesomeIcon icon="times"/>
                                  </span>
                                </td>
                              </tr>
                            ))}
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                      </>
                    )}
                  </FieldArray>
                  {/* Buttons at bottom of form */}
                  <Row>
                  <Col md={4}>
                      &nbsp;
                    </Col>
                    <Col md={4} className="d-flex align-items-center">
                      <Button color="info" onClick={() => saveDraft(values)} disabled={isSavingDraft}>Save
                        Draft</Button>
                      {displayDraftSavedMessage && <span className="ml-2 text-success strong">Draft Saved</span>}
                    </Col>
                    <Col md={4} className='ml-auto'>
                      <Button color="success" className='mr-3' type="submit" disabled={isSubmitting}>Submit</Button>
                      <Button color="warning" className='mr-3' onClick={onClose}>Cancel</Button>
                    </Col>
                  </Row>
                </Form>
              );
            }
          }
        </Formik>
      ) : (
        <div className='mt-3 mb-2 ml-1'>Waiting for Draft choice...</div>  // Or any other loading indicator
      )}
    </>
  );
};

