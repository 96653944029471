import React from 'react';
import {Button, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TimeAgo from "utils/TimeAgo";
import {
  IfAuthCanReport,
  IfAuthSuperAdminOrReadonly,
  IfNotAuthSuperReadonly,
  isAuthSuperAdminOrReadonly,
  isAuthSuper
} from "components/RBAC";
import Moment from "moment/moment";
import {Icon, Intent, Tag} from "@blueprintjs/core";
import ReactTooltip from 'react-tooltip';


export default (props) => {
  const {patient} = props;
  //const diseases = patient.diseases ? JSON.parse(patient.diseases) : DISEASES;

  const rebiopsy_cases = {};
  const rebiopsy_of_cases = {};

  /**
   * Does case's GPCL id match its sample tube labels?
   *
   * @param {string} caseId - GPCL id; (patient.case_id)
   * @param {object[]} samples - case's sample array  (patient.samples)
   * @returns {boolean} - true if matches, false if not
   */
  const doesCaseIdMatchSamples = (caseId, samples) => {
    // If there are no samples, consider it a match.
    if (!samples || !samples.length) return true;

    // no case id means no match
    if (!caseId) return false;

    // Sample tube labels should be in format:   caseid-dd   (e.g. 19501-03)
    // Match is bad if caseid of any sample tube label doesn't match case id
    // or tube label not in caseid-dd format
    for (let sample of samples) {
      const m = sample.tube_label.match(/^\s*(\S.*\S)\s*-\s*\S+?\s*$/);
      if (!m || caseId !== m[1]) return false;
    }

    return true;
  }


  if (patient.rebiopsy_connected_cases) {
    patient.rebiopsy_connected_cases.forEach((item) => {
      if (item.id !== patient.id) {
        if (!(item.id in rebiopsy_cases)) {
          rebiopsy_cases[item.id] = {'label': item.label, samples: []};
        }

        rebiopsy_cases[item.id].samples.push([item.sample, item.sample_rebiopsy]);
      }
    });
  }

  if (patient.rebiopsy_of_connected_cases) {
    patient.rebiopsy_of_connected_cases.forEach((item) => {
      if (item.id !== patient.id) {
        if (!(item.id in rebiopsy_of_cases)) {
          rebiopsy_of_cases[item.id] = {'label': item.label, samples: []};
        }

        rebiopsy_of_cases[item.id].samples.push([item.sample, item.sample_rebiopsy]);
      }
    });
  }

  return (
    <div className={'case-status-' + patient.case_status}>
      <Card>
        <CardHeader>
          <Row className="justify-content-between align-items-center">
            <Col>
              <h6 style={{margin: 0}}>
                Case{' '}
                {
                  !doesCaseIdMatchSamples(patient.case_id, patient.samples) &&
                  <span style={{color: 'red', marginLeft: 6}}>
                      GPCL ID not expected
                  </span>
                }
                {
                  patient.case_status === 'canceled' &&
                  <span className="text-warning" style={{marginLeft: 6}}>
                      <FontAwesomeIcon icon="ban"/> Canceled
                  </span>
                }
              </h6>
            </Col>
            <Col>
              <span className="text-muted">Created By: {patient.external_domain ? `EMR - ${patient.external_domain}` :
                                                        patient.creator} (<TimeAgo value={patient.created_at}/>)</span>
            </Col>
            <Col>
              <span className="text-muted">Last Update: <TimeAgo value={patient.updated_at}/></span>
            </Col>
            <Col md="auto">
              <IfNotAuthSuperReadonly>
                <Button
                  color="light"
                  size="sm"
                  onClick={() => props.onEdit()}
                >
                  <FontAwesomeIcon icon="pencil-alt"/> EDIT
                </Button>
              </IfNotAuthSuperReadonly>
            </Col>
            <Col md="auto">
              <IfNotAuthSuperReadonly>
                <Button
                  color={patient.is_archived ? 'success' : 'warning'}
                  size="sm"
                  onClick={() => props.putInArchive()}
                >
                  {
                    patient.is_archived ? 'Restore' : 'Archive'
                  }
                </Button>
              </IfNotAuthSuperReadonly>
            </Col>
            <IfNotAuthSuperReadonly>
            {
              patient.is_archived &&
              <Col md="auto">
                <ReactTooltip />
                <span data-tip="Delete all related samples first" data-tip-disable={!patient.number_of_samples}>
                  <Button
                    color="danger"
                    size="sm"
                    onClick={() => props.onDelete()}
                    disabled={patient.number_of_samples > 0}
                  >
                    <i className="fa fa-trash-o"/>{' '}Delete
                  </Button>
                </span>
              </Col>
            }
            </IfNotAuthSuperReadonly>
          </Row>
        </CardHeader>
        <CardBody>

          <Row>
            <Col>
              {!!patient.external_id && <p><strong>EMR Order Id:</strong> {patient.external_id}</p>}
              <p>
                <strong>Status:</strong>{' '}
                {patient.case_status === 'pending' &&
                <span className="text-muted"><FontAwesomeIcon icon="stopwatch"/> Pending</span>}
                {patient.case_status === 'processing' &&
                <span className="text-warning"><FontAwesomeIcon icon="sync"/> Processing</span>}
                {patient.case_status === 'complete' &&
                <span className="text-success"><FontAwesomeIcon icon="check"/> Complete</span>}
                {patient.case_status === 'sample_hold' && isAuthSuperAdminOrReadonly(props.user) &&
                <span className="text-muted"><FontAwesomeIcon icon="stopwatch"/> Sample Hold</span>}
                {patient.case_status === 'sample_hold' && !isAuthSuperAdminOrReadonly(props.user) &&
                <span className="text-muted"><FontAwesomeIcon icon="file"/> Processing</span>}
                {patient.case_status === 'report_hold' && isAuthSuperAdminOrReadonly(props.user) &&
                <span className="text-muted"><FontAwesomeIcon icon="stopwatch"/> Report Hold</span>}
                {patient.case_status === 'report_hold' && !isAuthSuperAdminOrReadonly(props.user) &&
                <span className="text-muted"><FontAwesomeIcon icon="file"/> Processing</span>}
                {patient.case_status === 'report_ready' && isAuthSuperAdminOrReadonly(props.user) &&
                <span className="text-muted"><FontAwesomeIcon icon="file"/> Report Ready</span>}
                {patient.case_status === 'report_ready' && !isAuthSuperAdminOrReadonly(props.user) &&
                <span className="text-muted"><FontAwesomeIcon icon="file"/> Processing</span>}
                {patient.case_status === 'case_accepted' && isAuthSuperAdminOrReadonly(props.user) &&
                <span className="text-muted"><FontAwesomeIcon icon="file"/> Case Accepted</span>}
                {patient.case_status === 'case_accepted' && !isAuthSuperAdminOrReadonly(props.user) &&
                <span className="text-muted"><FontAwesomeIcon icon="file"/> Processing</span>}
                {patient.case_status === 'gc_scheduled' && isAuthSuperAdminOrReadonly(props.user) &&
                <span className="text-muted"><FontAwesomeIcon icon="file"/> GC Scheduled</span>}
                {patient.case_status === 'gc_scheduled' && !isAuthSuperAdminOrReadonly(props.user) &&
                <span className="text-muted"><FontAwesomeIcon icon="file"/> Processing</span>}
                {patient.case_status === 'setup_complete' && isAuthSuperAdminOrReadonly(props.user) &&
                <span className="text-muted"><FontAwesomeIcon icon="file"/> Set Up Complete</span>}
                {patient.case_status === 'setup_complete' && !isAuthSuperAdminOrReadonly(props.user) &&
                <span className="text-muted"><FontAwesomeIcon icon="file"/> Processing</span>}
                {patient.case_status === 'embryo_samples_received' && isAuthSuperAdminOrReadonly(props.user) &&
                <span className="text-muted"><FontAwesomeIcon icon="file"/> Embryo Samples Received</span>}
                {patient.case_status === 'embryo_samples_received' && !isAuthSuperAdminOrReadonly(props.user) &&
                <span className="text-muted"><FontAwesomeIcon icon="file"/> Processing</span>}
              </p>

              <p>
                <strong>Payment:</strong>{' '}
                {patient.case_payment_status === 'awaiting' &&
                <span className="text-muted"><FontAwesomeIcon icon="stopwatch"/> Awaiting</span>}
                {patient.case_payment_status === 'received' &&
                <span className="text-success"><FontAwesomeIcon icon="check"/> Received</span>}
                {patient.case_payment_status === 'no_bill' &&
                <span className="text-warning"><FontAwesomeIcon icon="check"/> Do Not Bill</span>}
              </p>

              {/*<IfAuthSuper>*/}
              {/*  <p><strong>GPCL Barcode ID:</strong> {patient.case_id ? patient.case_id :*/}
              {/*    <span className="text-muted">TEMP{patient.id}</span>}</p>*/}
              {/*</IfAuthSuper>*/}

              <p><strong>GPCL Barcode ID:</strong> {patient.case_id ? patient.case_id :
                <span className="text-muted">-</span>}</p>

              <IfAuthCanReport>

                <p>
                  <strong>Client Reference:</strong> {patient.client_reference ? patient.client_reference : '-'}
                </p>
              </IfAuthCanReport>
              {
                !!patient.clinical_trial_id && <p>
                  <strong>Clinical Trial ID:</strong> {patient.clinical_trial_id}
                </p>
              }
              <p><strong>Referring Physician:</strong> {patient.clinician_name ? patient.clinician_name : '-'}</p>
              {!!patient.nurse_name && <p><strong>Nurse:</strong> {patient.nurse_name}</p>}

              <IfAuthSuperAdminOrReadonly>
                <p>
                  <strong>Case Completed Date:</strong> {
                  patient.completed_at ? (
                    <span>{Moment(patient.completed_at, 'YYYY-MM-DD').format('MM/DD/YYYY')}</span>
                  ) : '-'
                }
                </p>
                {
                  patient.test_update_date ? (
                    <p>
                      <strong>Completed Date after Test Update:</strong> {
                      patient.completed_at_after_test_update ? (
                        <span>{Moment(patient.completed_at_after_test_update, 'YYYY-MM-DD').format('MM/DD/YYYY')}</span>
                      ) : '-'
                    }
                    </p>
                  ) : null
                }
                <p>
                  <strong>Rebiopsy?:</strong> {patient.rebiopsy_flag ? 'Yes' : 'No'}
                </p>
                <p>
                  <strong>Patient Consent Signed Date:</strong> {
                  patient.consent_signed_date ? (
                    <span>{Moment(patient.consent_signed_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}</span>
                  ) : '-'
                }
                </p>
                {patient.partner ? (
                <p>
                  <strong>Partner Consent Signed Date:</strong> {
                  patient.partner_consent_signed_date ? (
                    <span>{Moment(patient.partner_consent_signed_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}</span>
                  ) : '-'
                }
                </p>) : ''}
                <p>
                  <strong>Consented for
                    Research?:</strong> {patient.consented_research === null ? '-' : (patient.consented_research ? 'Yes' : 'No')}
                </p>
                <p>
                  <strong>Consented for Sample
                    Retention?:</strong> {patient.consented_sample_retention === null ? '-' : (patient.consented_sample_retention ? 'Yes' : 'No')}
                </p>
                <p>
                  <strong>Report Sent Date:</strong> {
                  patient.report_sent_date ? (
                    <span>{Moment(patient.report_sent_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}</span>
                  ) : '-'
                }
                </p>
                <p>
                  <strong>Setup Completed:</strong> {
                  patient.setup_completed_date ? (
                    <span>{Moment(patient.setup_completed_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}</span>
                  ) : '-'
                }
                </p>
              </IfAuthSuperAdminOrReadonly>
            </Col>

            <Col>
              {
                !patient.m2 && (
                  <div>
                    <p><strong>Conventional IVF:</strong> {patient.conventional_ivf ? 'Yes' : 'No'}</p>
                    <p><strong>ICSI:</strong> {patient.icsi ? 'Yes' : 'No'}</p>
                    <p><strong>Sex of
                      Embryo:</strong> {patient.hide_sex === null ? 'Not Selected' : (patient.hide_sex ? 'Do Not Report' : 'Report')}
                    </p>
                    <p>
                      <strong>Expected Egg Retrieval/Thaw Date:</strong> {patient.expected_egg_retrieval ? (
                      <span>{Moment(patient.expected_egg_retrieval, 'YYYY-MM-DD').format('MM/DD/YYYY')}</span>
                    ) : '-'}
                    </p>
                    {patient.provider_report_embryo_id && (
                      <p>
                      <strong>Report Embryo Id: </strong>
                      {patient.report_embryo_id ? "Yes" : "No"}
                      </p>
                    )}
                    <p>
                    <strong>Report Embryo Grade: </strong>
                    {patient.report_embryo_grade ? "Yes" : "No"}
                    </p>
                    <p>
                    <strong>Report Biopsy Day: </strong>
                    {patient.report_biopsy_day ? "Yes" : "No"}
                    </p>
                    <p>
                    <strong>Report Cycle #: </strong>
                    {patient.report_cycle_number ? "Yes" : "No"}
                    </p>
                    {
                      patient.report_cycle_number && (
                        <p>
                          <strong>Cycle #: </strong>
                          {patient.cycle_number}
                        </p>
                      )
                    }
                    <p>
                      <strong>Billing Type: </strong>
                      {patient.billing_type || '-'}
                    </p>

                    <p><strong>Egg Donor:</strong> {patient.egg_donor ? 'Yes' : 'No'}</p>
                    <p><strong>Sperm Donor:</strong> {patient.sperm_donor ? 'Yes' : 'No'}</p>
                  </div>
                )
              }
            </Col>

            <Col>
              <p>
                <strong>Test Type:</strong>
                {!patient.m2 && <span>PGT-A{patient.pgt_a_plus && '+'} </span>}
                {patient.pgt_m && ' PGT-M '}
                {patient.pgt_p && ' PGT-P '}
                {patient.pgt_s && ' PGT-SR '}
                {patient.m2 && ' M2 '}
                {patient.test_update_date && isAuthSuper(props.user) ? (
                  <span style={{marginLeft: '10px', color: '#7c69de'}}>
                    (Updated: {Moment(patient.test_update_date, 'YYYY-MM-DD').format('MM/DD/YYYY')},
                    {' '}previous:{' '}
                    {!patient.prev_tests.match(/m2/) && 'PGT-A'}
                    {patient.prev_tests.match(/pgt_a_plus/) && '+'}
                    {patient.prev_tests.match(/pgt_m/) && ' PGT-M'}
                    {patient.prev_tests.match(/pgt_p/) && ' PGT-P'}
                    {patient.prev_tests.match(/pgt_s/) && ' PGT-SR'}
                    {patient.prev_tests.match(/m2/) && ' M2'}
                    )
                  </span>
                ) : null}
              </p>

              {
                patient.m2 && (
                  <div style={{marginTop: 12, marginBottom: 12}}>
                    <strong>M2 Blinded: </strong>
                    {
                      patient.blinded ? <Icon icon={'tick'} intent={Intent.SUCCESS}/> : <Icon icon={'small-minus'}/>
                    }
                  </div>
                )
              }

              {
                patient.pgt_p && (
                  <div style={{marginTop: 12, marginBottom: 12}}>
                    <strong>Panel: </strong>
                    {patient.panel ? patient.panel.charAt(0).toUpperCase() + patient.panel.slice(1) + ' panel' : ''}
                  </div>
                )
              }
              <IfAuthCanReport>
                <p><strong>Samples Received Date:</strong>{' '}
                  <span>{patient.samples_received ? Moment(patient.samples_received, 'YYYY-MM-DD').format('MM/DD/YYYY') : '-'}</span>
                </p>
                <p><strong>Saliva Received Date:</strong>{' '}
                  <span>{patient.saliva_received_date ? Moment(patient.saliva_received_date, 'YYYY-MM-DD').format('MM/DD/YYYY') : '-'}</span>
                </p>
              </IfAuthCanReport>

              <p>
                <strong>GC Pre-Test Date:</strong> {
                patient.gc_pretest_date ? (
                  <span>{Moment(patient.gc_pretest_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}</span>
                ) : '-'
              }
              </p>
              <p>
                <strong>GC Post-Test Date:</strong> {
                patient.gc_posttest_date ? (
                  <span>{Moment(patient.gc_posttest_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}</span>
                ) : '-'
              }
              </p>

              <IfAuthSuperAdminOrReadonly>
                <p><strong>Study:</strong> {patient.study ? patient.study : '-'}</p>
                <p><strong>Study Notes:</strong> {patient.study_notes ? patient.study_notes : '-'}</p>
              </IfAuthSuperAdminOrReadonly>
            </Col>
          </Row>

          {
            !patient.m2 && (
              <div>
                <hr/>
                <p><strong>Final Report Release Reporting Structure</strong></p>
                {patient.final_report_release_reporting_structure
                  ? <pre style={{fontFamily: "Roboto,sans-serif", fontSize: "inherit"}}>{patient.final_report_release_reporting_structure}</pre>
                    : null
                  }
              </div>
            )
          }

          {
            (Object.keys(rebiopsy_of_cases).length > 0 || Object.keys(rebiopsy_cases).length > 0) && (
              <div>
                <hr/>
                <p><strong>Rebiopsy</strong></p>

                <Row>
                  <Col>
                    {
                      Object.keys(rebiopsy_cases).map((case_id) => {
                        const item = rebiopsy_cases[case_id];

                        return (
                          <div>
                            <a
                              href={`/patients/${case_id}${window.location.search}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Case <strong>{item.label ? item.label : '(No Label)'}</strong>
                            </a>{' '}
                            Samples:{' '}
                            {
                              item.samples.map((sample) =>
                                <div style={{display: 'inline-block', paddingLeft: 4, paddingRight: 4}}>
                                  <Tag round minimal intent={Intent.PRIMARY}>
                                    {sample[0]} <Icon icon={'arrow-right'} intent={Intent.PRIMARY}/>
                                    <strong>{sample[1]}</strong>
                                  </Tag>
                                </div>
                              )
                            }
                          </div>
                        )
                      })
                    }

                    {
                      Object.keys(rebiopsy_of_cases).map((case_id) => {
                        const item = rebiopsy_of_cases[case_id];

                        return (
                          <div>
                            <a
                              href={`/patients/${case_id}${window.location.search}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Case <strong>{item.label ? item.label : '(No Label)'}</strong>
                            </a>{' '}
                            Samples:{' '}
                            {
                              item.samples.map((sample) =>
                                <div style={{display: 'inline-block', paddingLeft: 4, paddingRight: 4}}>
                                  <Tag round minimal intent={Intent.PRIMARY}>
                                    <strong>{sample[0]}</strong> <Icon icon={'arrow-right'}
                                                                       intent={Intent.PRIMARY}/> {sample[1]}
                                  </Tag>
                                </div>
                              )
                            }
                          </div>
                        )
                      })
                    }
                  </Col>
                </Row>
              </div>
            )
          }
        </CardBody>
      </Card>
    </div>
  )
}
