import React, {useEffect, useState} from "react";
import {Page, PageContent} from "components/Views";
import axios from "axios";
import {getToken} from "../../../auth/token";
import {Card, CardBody} from "reactstrap";
import TimeCreated from "../../../utils/TimeCreated";
import {ETHNICITIES} from "../../../constants/case";

// Does given field value represent a journal tag (e.g. for a MODEL)
const isJournalTag = v => (typeof v === 'string' && v.startsWith('{"_tag":'));

// Standard way to display null value.   Should this be CSS instead?  probably.
const DisplayNone = ({text}) => <i style={{color: 'grey'}}>{text ? text : 'none'}</i>;

// Standard way to display key value in objects.   Should this be CSS instead?  probably.
const DisplayKey = ({k}) => <span style={{color: '#8888ff', whiteSpace: 'nowrap'}}>{k.replace(/_/g, ' ')}</span>;

// If datetime has no timezone, make sure it's demarcated as UTC.  Otherwise, system assumes local time.
const fixTimeZone = ts => {
  if (ts.indexOf('+') > -1 || ts.indexOf('Z') > -1 || ts.indexOf('z') > -1) return ts;
  return `${ts}Z`;
}

// To simplify display of nested objects;
// If keys of given object are consecutive integers starting with 0,
// convert it to an array and return.  Otherwise, just return object.
const reduceArray = (o) => {
  if (typeof o !== 'object') return o;
  let m;
  try {
    const upperBound = Object.keys(o).length - 1;
    for (const k of Object.keys(o)) {
      m = k.match(/^(\d+)$/);
      if (!m) return o;
      const n = parseInt(m[1]);
      if (n < 0 || n > upperBound) return o;
    }

    // keys are from "0" to upperBound inclusive

    const retVal = [];
    for (let i = 0; i <= upperBound; i++) {
      retVal.push(o[i.toString()]);
    }

    return retVal;
  } catch(e) {
    return o;
  }
}


// How to display each field name (label).  If field not present here, default is Title Case.
// This object is good for renaming, optionally designating section and/or custom display function
const patientFieldLabels = {
  clinician: {section: null, label: 'Referring Physician'},
  created_at: {section: null, label: 'Created'},
  ethnicity: {section: null, label: 'Ethnicity', displayFunction: v => v in ETHNICITIES ? ETHNICITIES[v] : v },
  external_domain: {section: null, label: 'EMR Submitter'},
  external_id: {section: null, label: 'EMR Id'},
  nurse: {section: null, label: 'Nurse'},
  partner_ethnicity: {section: null, label: 'Partner Ethnicity', displayFunction: v => v in ETHNICITIES ? ETHNICITIES[v] : v },
  patient: {section: null, label: null},
  provider: {section: null, label: 'Clinic'},
  samples_result_created_at: {section: null, label: 'Results first synced at'}
}

// For all models (tables), don't display these attributes (fields)
const ignoreAttributesGlobal = {
  address_id: true,
  card_id: true,
  clinics: true,
  clinician_id: true,
  created_by_id: true,
  distributor_id: true,
  id: true,
  kit_id: true,
  kit_provider: true,
  kitprovider: true,
  patient_id: true,
  partner_id: true,
  person_id: true,
  provider_id: true,
  rebiopsy_sample_id: true,
  saliva_sample_id: true,
  shipping_address_id: true,
  updated_at: true,
  user_id: true,
};

// For these specific models, don't display these attributes (fields)
const ignoreAttributesModel = {
  Address: {
    as_string: true,
  },
  Patient: {
    application_id: true,
    card: true,
    clinician_id: true,
    diseases: true,
    gc_meeting_id: true,
    prev_application_completed: true,
    prev_case_id: true,
    prev_case_status: true,
    prev_consent_form: true,
    prev_files: true,
    prev_gc_date_time: true,
    prev_pgt_m: true,
    prev_pgt_p: true,
    prev_pgt_s: true,
    prev_reports: true,
  },
  Provider: {
    distributor: true,
    kits: true
  },
  SalivaSample: {
    provider_company_name: true,
  },
  Sample: {
    case_id: true,
    biopsy_clinician_id: true,
    loading_clinician_id: true
  },
  User: {
    distributor: true
  }
};

// When showing INITIAL or DELETED, for these models, don't display attributes (fields) matching these default values
// null show as none, '' show as blank
const defaultAttributesModel = {
  Address: {
    address_2: ''
  },
  Clinician: {
    code: null,
    image: null,
  },
  Patient: {
    "account_manager": null,
    "address": null,
    "admin_and_analysis_fee": null,
    "application_completed": false,
    "application_id": null,
    "billing_email": null,
    "billing_note": null,
    "billing_options": null,
    "billing_type": null,
    "blinded": false,
    "card": null,
    "card_id": null,
    "case_billed_amount": null,
    "case_id": null,
    "case_name": null,
    "case_paid_amount": null,
    "case_payment_status": "awaiting",
    "case_status": "pending",
    "case_total_fee": null,
    "client_reference": null,
    "clinical_trial_id": null,
    "clinician": null,
    "clinician_id": null,
    "completed_at": null,
    "consent_form": null,
    "consent_signed_date": null,
    "partner_consent_signed_date": null,
    "consented_research": null,
    "consented_sample_retention": null,
    "conventional_ivf": false,
    "created_at": null,
    "created_by": null,
    "created_by_id": null,
    "cycle_number": "",
    "diseases": [null,"[\"Type 1 Diabetes\",\"Type 2 Diabetes\",\"Coronary Artery Disease\",\"Heart Attack\",\"Atrial Fibrillation\",\"Inflammatory Bowel Disease\",\"Asthma\",\"Testicular Cancer\",\"Breast Cancer\",\"Prostate Cancer\",\"Malignant Melanoma\",\"Basal Cell Carcinoma\",\"Schizophrenia\"]"],
    "dob": null,
    "egg_donor": false,
    "email": null,
    "email2": null,
    "ethnicity": null,
    "expected_egg_retrieval": null,
    "external_domain": null,
    "external_id": null,
    "fdh": null,
    "files": null,
    "first_name": null,
    "gc_pretest_date": null,
    "gc_posttest_date": null,
    "gc_date_time": null,
    "gc_meeting_id": null,
    "gc_notes": null,
    "gc_notification_sent": false,
    "gc_requested": false,
    "gc_scheduled": false,
    "genetic_reports": "",
    "gp_files": null,
    "gseq": false,
    "hide_sex": null,
    "hide_sex_in_table": true,
    "icsi": false,
    "intake_fee": null,
    "intake_fee_sent_date": null,
    "intake_paid_date": null,
    "invoice_billed_date": null,
    "invoice_number": null,
    "invoice_paid_date": null,
    "is_archived": false,
    "last_name": null,
    "m2": false,
    "m_fdh": null,
    "notes": null,
    "num_biopsies": null,
    "nurse": null,
    "nurse_id": null,
    "panel": "comprehensive",
    "partner": null,
    "partner_dob": null,
    "partner_ethnicity": null,
    "partner_fdh": null,
    "partner_first_name": null,
    "partner_id": null,
    "partner_last_name": null,
    "partner_sex": null,
    "partner_ssn": null,
    "patient": null,
    "patient_id": null,
    "per_sample_fee": null,
    "pgt_a": true,
    "pgt_a_plus": false,
    "pgt_m": false,
    "pgt_m_target": null,
    "pgt_m_target_code": null,
    "pgt_p": false,
    "pgt_s": false,
    "phone": null,
    "phone2": null,
    "prev_application_completed": false,
    "prev_case_id": null,
    "prev_case_status": "pending",
    "prev_consent_form": null,
    "prev_files": null,
    "prev_gc_date_time": null,
    "prev_pgt_m": false,
    "prev_pgt_p": false,
    "prev_pgt_s": false,
    "prev_reports": null,
    "provider": null,
    "provider_id": null,
    "rebiopsy_date": null,
    "rebiopsy_flag": [null, false],
    "report_biopsy_day": null,
    "report_cycle_number": null,
    "report_disclaimer": null,
    "report_embryo_grade": null,
    "report_embryo_id": null,
    "report_info": null,
    "report_logo": null,
    "report_sent_date": null,
    "reported_at": null,
    "reports": null,
    "saliva_received_date": null,
    "samples_form": "",
    "samples_received": null,
    "samples_result_created_at": null,
    "send_registration_email": false,
    "setup_completed_date": null,
    "setup_fee": null,
    "setup_paid_date": null,
    "setup_send_date": null,
    "sex": null,
    "shipping_fee": null,
    "show_failed": false,
    "show_index": true,
    "show_risk": true,
    "sperm_donor": false,
    "sr_fdh": null,
    "ssn": null,
    "study": ["", null],
    "study_notes": ["", null],
    "test_performed_by": null,
    "test_requested_at": null,
    "test_requisitions": "",
    "test_update_date": null,
    "completed_at_after_test_update": null,
    "fee_after_test_update": null,
    "paid_amount_after_test_update": null,
    "invoice_number_after_test_update": null,
    "invoice_billed_after_test_update": null,
    "invoice_paid_after_test_update": null,
    "updated_at": null
  },
  Person: {
    address: null,
    address_same: false,
    archived: false,
    dob: null,
    email: '',
    ethnicity: '',
    external_domain: null,
    external_id: null,
    fdh: ['', null],
    phone: '',
    provider: null,
    qreview_sent: false,
    related_as: ['', null],
    related_to: ['', null],
    sex: '',
  },
  Provider: {
    billing_structure_a: '',
    billing_structure_aplus: '',
    billing_structure_m: '',
    billing_structure_sr: '',
    billing_structure_p: '',
    billing_structure_m2: '',
    bucket: null,
    can_report: false,
    card: null,
    company_address: '{}',
    distributor: null,
    email: '',
    external_domain: null,
    external_id: null,
    final_report_release_reporting_structure: '',
    first_name: null,
    geo_location: '',
    hide_sex: false,
    include_biopsy_day: false,
    include_embryo_grade: false,
    include_embryo_id: false,
    last_name: null,
    m2_enabled: false,
    show_cycle_number: false,
    tel: '',
    terms_accepted: false
  },
  SalivaSample: {
    collection_date: null,
    label: '',
    person: null,
    received_at: null,
    sent_at: null,
    sample_type: '',
    status: null
  },
  Sample: {
    aneuploid: false,
    biopsy_clinician: null,
    biopsy_day: null,
    biopsy_time: null,
    complex_aneuploid: false,
    conventional_ivf: false,
    cycle: null,
    embryo_grade: null,
    embryo_id: '',
    embryo_number: null,
    external_domain: null,
    icsi: false,
    inconclusive: false,
    karyotype: null,
    loading_clinician: null,
    m_status: null,
    m_status_custom: null,
    no_amp: false,
    no_result: false,
    notes: '',
    order: null,
    order_id: null,
    panel: null,
    provider: null,
    qc_check: null,
    rebiopsy_sample: null,
    rejected: false,
    sample_results: null,
    sr_status: null,
    sr_status_final: null,
    status: null,
    timelapse: null,
    transferred: false,
    transferred_outcome: '',
    transferred_outcome_is_reported: false,
    witness_clinician: null
  },
  User: {
    distributor: null,
    email: '',
    first_name: '',
    last_name: '',
    gc: null,
    is_active: true,
    is_staff: false,
    is_superuser: false,
    last_login: null,
    provider: null
  }
};

const fixLogCaseMapping = {
  address: 'Address',
  clinician: 'Clinician',
  created_by: 'User',
  nurse: 'Nurse',
  patient: 'Person',
  partner: 'Person',
  provider: 'Provider'
};

// Kludge to include missing MODEL tags in delta entries
const fixDeltaModelMapping = {
  address_id: 'Address',
  clinician_id: 'Clinician',
  kit_id: 'Kit',
  patient_id: 'Patient',
  partner_id: 'Person',
  person_id: 'Person',
  provider_id: 'Provider',
  rebiopsy_sample_id: 'Sample',
  sample_id: 'Sample',
  saliva_sample_id: 'SalivaSample',
  shipping_address_id: 'Address'
};

// Standard format for expressing Model+id
// e.g. ('Case', 772) => "Case (772)"
const fmtModelId = (modelName, id) => `${modelName} (${id})`;

// Parse a standard 'Model (id)' string into ModelName and id
// e.g. "Case (772)" => ['Case', '772']
const parseModelIdLabel = (modelNameId) => {
  const m = modelNameId.match(/^(\S+)\s+\((\d+)\)$/);
  if (m) return [m[1], m[2]];
  return ['??','??'];
}

// Safely convert journal entry's json_content into a {field: value} object
const parseInstanceFromJournalEntry = (entry) => {
  try {
    return JSON.parse(entry.json_content);
  } catch(e) {
    return null;
  }
}

// Given a field and value, find its prior value in journal.
// Since rows are in reverse chronological order, search progresses
// forward, instead of backward, for previous records.
// startIndex is initially next row.
const findPrevFieldValue = (entries, startIndex, modelName, id, fieldName, value) => {
  if (startIndex >= entries.length) {
    return { status: false, value: null };
  }

  if (entries[startIndex].model_name === modelName && entries[startIndex].row_id === id) {
    const instance = parseInstanceFromJournalEntry(entries[startIndex]);
    if (instance && (fieldName in instance)) {
      return {status: true, value: instance[fieldName]};
    }
  }

  return findPrevFieldValue(entries, startIndex + 1, modelName, id, fieldName, value);
}

// change given field_name in snake_case to Title Case field name
// e.g. convert 'some_var' to 'Some Var'
const fieldNameToLabel = (f) => {
  let outName = f.replaceAll('_', ' ');
  let found = outName.match( /(^.*\b)([a-z])(.*$)/ );
  while (found) {
    outName = `${found[1]}${found[2].toUpperCase()}${found[3]}`;
    found = outName.match( /(^.*\b)([a-z])(.*$)/ );
  }
  return outName;
}

// Display field name (label).  If it has preferred value in patientFieldLabels, use that.
// Otherwise, display it in Title Case.
const displayFieldName = (modelName, fieldName) => {
  if (fieldName === 'patient')
    return modelName === 'Patient' ? 'Patient' : 'Case';

  if (fieldName in patientFieldLabels) {
    const label = 'label' in patientFieldLabels[fieldName] && patientFieldLabels[fieldName].label ? patientFieldLabels[fieldName].label : fieldNameToLabel(fieldName);
    if ('section' in patientFieldLabels[fieldName] && patientFieldLabels[fieldName].section)
      return `${patientFieldLabels[fieldName].section} - ${label}`;
    return label;
  }

  return fieldNameToLabel(fieldName);
}

// Don't display this model attribute (field) if it's in the global or model-specific ignore dictionaries.
const ignoreThisAttribute = (f, modelName) => {
  if (f in ignoreAttributesGlobal) return true;
  return !!(modelName in ignoreAttributesModel && ignoreAttributesModel[modelName][f]);
}

// Find the first Full journal entry for current case (Patient)
const findEarliestPatientFullEntry = entries => {
  for (let i = entries.length - 1; i >= 0; i -= 1)
    if (entries[i].model_name === 'Patient' && entries[i].entry_type === 'F') return entries[i];
  return null;
}

// Because Journal was created September 2023 and was back-filled then, there are many
// pieces of data that originated before then.  Modify journal's creation time stamps to match data.
// [commented out because it seems to be causing more problems than it solves.
//  e.g. case's updated_at doesn't update for fdh and other changes]
// const adjustCreationTimestamps = (entries) => {
//   entries.forEach(entry => {
//     const instance = parseInstanceFromJournalEntry(entry);
//     if (instance && ('updated_at' in instance) && instance.updated_at) {
//       try {
//         if (Date.parse(fixTimeZone(instance.updated_at)) < Date.parse(fixTimeZone(entry.created_at))) {
//           entry.real_created_at = fixTimeZone(entry.created_at);
//           entry.created_at = fixTimeZone(instance.updated_at);
//         }
//       } catch (e) {
//         console.log('Warning: error in adjustCreationTimestamps', e);
//       }
//     }
//   });
// }

// JournalSection component - display Change Log of clinic portal
// props.data = entire patient hierarchy
export default (props) => {
  // props.data should be entire patient instance hierarchy
  const patient_id = props.data ? props.data.id : null;
  const [journalEntries, setJournalEntries] = useState([]);
  const [initialCutoffTime, setInitialCutoffTime] = useState(undefined);

  // call initialize when component instantiated
  useEffect( () => {
    initialize().then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Get all journal entries with given modelname + id
  const getJournalEntries = async (modelName, id)=> {
    return axios({
      method: 'GET',
      responseType: 'json',
      baseURL: process.env.REACT_APP_API_HOST,
      url: `journal/${modelName}/${id}`,
      headers: {
        "Authorization": `Token ${getToken()}`
      }
    });
  }

  // Given array of entries from Journal (in reverse chron order),
  // return most recent entry that contains value for given field in its json_content.
  // Initially look for latest entry below startingIndex.
  // If none found there, look for closest entry after starting index.
  const latestEntryValue = (modelName, id, entries, fieldName, startingIndex=0) => {
    for (let i = startingIndex; i < entries.length; i += 1) {
      const entry = entries[i];
      if (entry.model_name === modelName && entry.row_id === id) {
        const instance = parseInstanceFromJournalEntry(entry);
        if (instance && (fieldName in instance)) return instance[fieldName];
      }
    }
    for (let i = startingIndex - 1; i >= 0; i -= 1) {
      const entry = entries[i];
      if (entry.model_name === modelName && entry.row_id === id) {
        const instance = parseInstanceFromJournalEntry(entry);
        if (instance && (fieldName in instance)) return instance[fieldName];
      }
    }
    return '???';
  }

  // display model+id as its user-friendly name (e.g. person first name and last) if possible, otherwise "Model (id)"
  // E.g. given 'Person 1001', lookup through given journal entries to derive 'Person: Joan Smith'
  const lookupModelIdLabel = (modelName, id, journalEntries, index=0) => {

    if (modelName === 'Patient') {
      const externalDomain = latestEntryValue(modelName, id, journalEntries, 'external_domain', index);
      const externalId = latestEntryValue(modelName, id, journalEntries, 'external_id', index);
      if (externalDomain && externalId)
        return `Case: EMR ${externalDomain} - ${externalId}`;

      const caseId = latestEntryValue(modelName, id, journalEntries, 'case_id', index);
      if (caseId) return `Case: ${caseId}`;

      return `Case: #${latestEntryValue(modelName, id, journalEntries, 'id', index)}`;
    }

    if (modelName === 'Address')
      return `Address: ${latestEntryValue(modelName, id, journalEntries, 'address_1', index)}, ${latestEntryValue(modelName, id, journalEntries, 'city', index)}`;

    if (modelName === 'Clinician')
      return `Dr. ${latestEntryValue(modelName, id, journalEntries, 'first_name', index)} ${latestEntryValue(modelName, id, journalEntries, 'last_name', index)}`;

    if (modelName === 'Distributor')
      return `Distributor: ${latestEntryValue(modelName, id, journalEntries, 'company_name', index)}`;

    if (modelName === 'Nurse')
      return `Nurse: ${latestEntryValue(modelName, id, journalEntries, 'first_name', index)} ${latestEntryValue(modelName, id, journalEntries, 'last_name', index)}`;

    if (modelName === 'Person')
      return `Person: ${latestEntryValue(modelName, id, journalEntries, 'first_name', index)} ${latestEntryValue(modelName, id, journalEntries, 'last_name', index)}`;

    if (modelName === 'Provider')
      return `Clinic: ${latestEntryValue(modelName, id, journalEntries, 'company_name', index)}`;

    if (modelName === 'Sample')
      return `BioSample-Tube: ${latestEntryValue(modelName, id, journalEntries, 'tube_label', index)}`;

    if (modelName === 'SalivaSample')
      return `SalivaSample-Tube: ${latestEntryValue(modelName, id, journalEntries, 'tube_label', index)}`;

    if (modelName === 'User')
      return `User: ${latestEntryValue(modelName, id, journalEntries, 'username', index)}`;

    return `${modelName} #${id}`;
  }


  // in given Patient entry from old log_case table,
  // convert any joins to model tags
  const convertLogCaseTags = (entry, instance) => {
    let changeMade = false;
    for (let f of Object.keys(instance)) {
      if (f in fixLogCaseMapping) {  // 'patient', 'partner', ...
        if (instance[f] !== null && instance[f] !== '') {
          instance[f] = JSON.stringify({
            '_tag': 'MODEL',
            'model_name': fixLogCaseMapping[f],
            'id': instance[f]
          });
          changeMade = true;
        }
      }
    }

    if (changeMade) entry.json_content = JSON.stringify(instance);
  }


  // Seth: Despite my best efforts, sometimes MODEL tags aren't included in delta entries.  Mea Culpa.
  // So we add them here and pretend they were there all along.  >>KLUDGE<<
  const fixDeltaModelTags = (modelName, entry, instance) => {
    // Step 1 - inventory the models that are present
    const modelsPresent = {};
    for (let f of Object.keys(instance)) {
      if (ignoreThisAttribute(f, modelName)) continue;

      if (isJournalTag(instance[f])) {
        const o = JSON.parse(instance[f]);
        if (o['_tag'] === 'MODEL') {
          if (o.model_name in modelsPresent)
            modelsPresent[modelName].push(o.id)
          else
            modelsPresent[modelName] = [o.id];
        }
      }
    }

    // Step 2 - fill in any models that are missing
    let changeMade = false;
    for (let f of Object.keys(instance)) {
      if (f in fixDeltaModelMapping) {  // 'patient_id', 'person_id', 'sample_id' ...
        const v = instance[f];
        const modelLookup = f === 'patient_id' && modelName === 'Patient' ? 'Person' : fixDeltaModelMapping[f];
        if (!(modelLookup in modelsPresent) || modelsPresent[modelLookup].indexOf(v) === -1) {
          changeMade = true;
          instance[f.replace('_id', '')] = JSON.stringify({
              '_tag': 'MODEL',
              'model_name': modelLookup,
              'id': v
          });
        }
      }
    }

    if (changeMade) entry.json_content = JSON.stringify(instance);
  }

  // Read in all journal entries attached to case (Patient),
  // and all entries attached to relevant joined data - Person, Samples ...
  const initialize = async () => {
    let modelName = 'Patient';
    let id = patient_id;
    let resp = null;

    // Sort for journal entries once they've all been read in.
    // Sort by creation datetime descending (id descending if a tie)
    //
    // Entries without a case_log_entry_type are from journal, and occur before older entries from log_case
    // Entries from log_case either have type P (previous) or S (current state).
    const journalSortCompare = (a, b) => {
      if ( Date.parse(fixTimeZone(a.created_at)) > Date.parse(fixTimeZone(b.created_at)) ) return -1;
      if ( Date.parse(fixTimeZone(a.created_at)) < Date.parse(fixTimeZone(b.created_at)) ) return 1;
      if (!('case_log_entry_type' in a) && ('case_log_entry_type' in b)) return -1;
      if (('case_log_entry_type' in a) && !('case_log_entry_type' in b)) return 1;
      if ( a.id > b.id ) return -1;
      if ( a.id < b.id ) return 1;
      if (('case_log_entry_type' in a) && ('case_log_entry_type' in b)) {
        if (a.case_log_entry_type === "S")
          return -1;
        else
          return 1;
      }
      return 0;
    }

    // journal read error callback
    const journalErrFunc = err => {
        console.error(`getJournalEntries failed on ${modelName} ${id}:`, err);
        resp = null;
    };

    // Is given journal entry unique?  I.e. not a duplicate of earlier entry.
    const isUniqueEntry = (entries, entry, lowerBound, instance1) => {
      if (entries.length === 0 || entry.entry_type !== 'F') return true;

      const keys = Object.keys(instance1)
      for (let i = lowerBound + 1; i < entries.length; i += 1) {
        if (entry.model_name === entries[i].model_name && entry.row_id === entries[i].row_id) {
          if (entries[i].entry_type !== 'F') return true;

          // Matching journal entry found.  Are there any differences?

          const instance2 = parseInstanceFromJournalEntry(entries[i]);
          if (keys.length !== Object.keys(instance2).length) return true;

          for (let j = 0; j < keys.length; j += 1) {
            if (keys[j] !== 'updated_at' &&
              ((!(keys[j] in instance2)) ||  (instance1[keys[j]] !== instance2[keys[j]]))) return true;
          }

          return false;
        }
      }

      // no other matching entry found
      return true;
    };

    // modelInstancesToRetrieve - list of Model+id rows to retrieve.  E.g. 'Patient (781)'
    //      This list grows as model-joins are discovered

    // return modelNameId of first item in our modelNameList that we haven't started retrieving yet
    const getNextModelInstanceToRetrieve = (modelNameIdList) => {
      for (let modelNameId of Object.keys(modelNameIdList)) {
        if (modelNameIdList[modelNameId]) return modelNameId;
      }
      return false;
    }

    // Pull all the Journal entries we need, starting with Patient+id,
    // and then pulling entries for model+id it joins

    const modelInstancesToRetrieve = {};
    let modelIdLabel = fmtModelId('Patient', id);
    let entries = [];
    while (modelIdLabel) {
      [modelName, id] = parseModelIdLabel(modelIdLabel);

      // Get all journal entries for this model+id
      resp = await getJournalEntries(modelName, id).catch(journalErrFunc);

      modelInstancesToRetrieve[modelIdLabel] = false;  // prevent recursive looping

      if (resp && ('data' in resp) && resp.data && resp.data.length) {
        // Go through row(s) just retrieved.  If they contain any models, add those
        // to our list of Journal rows to retrieve.

        // But first, have to make sure they're in the right order...

        // Because journal was created after some data already existed, have to
        // "correct" the created date of those entries so that sorting will work the way we want.
        // adjustCreationTimestamps(resp.data);

        const sortedEntries = resp.data.sort(journalSortCompare);  // latest to earliest
        for (let i = sortedEntries.length - 1; i >= 0; i -= 1) {  // earliest to latest
          const entry = sortedEntries[i];
          // instance is dictionary of the row from db that was journaled (Patient, Person ...)
          const instance = parseInstanceFromJournalEntry(entry);

          // In entries originating from log_case, models like 'partner' have values that
          // are ids instead of model tags, so convert those here
          if ('case_log_entry_type' in entry) convertLogCaseTags(entry, instance);

          // Seth: Despite my best efforts, sometimes MODEL tags aren't included in delta entries.  Mea Culpa.
          // So we add them here and pretend they were there all along.  >>KLUDGE<<
          if (entry.entry_type === 'D') fixDeltaModelTags(modelName, entry, instance);

          // If journal entry exactly the same as previous one for same model+id, skip it
          if (!isUniqueEntry(sortedEntries, entry, i, instance)) continue;

          // Go through every field in this row looking for model joins we haven't added yet
          for (let f of Object.keys(instance)) {
            if (ignoreThisAttribute(f, modelName)) continue;

            let v = assessInstanceValue(instance[f]);
            if (v.isModel) {
              if (v.id) {
                const miKey = fmtModelId(v.modelName, v.id);
                if (!(miKey in modelInstancesToRetrieve)) modelInstancesToRetrieve[miKey] = true;
              }
            } else if (v.isModelArray) {
              for (let subId of v.ids) {
                const miKey = fmtModelId(v.modelName, subId);
                if (!(miKey in modelInstancesToRetrieve)) modelInstancesToRetrieve[miKey] = true;
              }
            }
          }

          // entries = all Journal rows we've retrieved
          entries.push(entry);
        }
      }

      modelIdLabel = getNextModelInstanceToRetrieve(modelInstancesToRetrieve);
    }

    const sortedEntries = entries.sort(journalSortCompare);

    const earliestPatientEntry = findEarliestPatientFullEntry(sortedEntries);
    if (earliestPatientEntry) {
      // We only want to see journal entries for data attached to Patient (case)
      // created on/after the Patient (case) was created.
      // E.g. don't show INITIAL for Provider created before case.
      const instance = parseInstanceFromJournalEntry(earliestPatientEntry);
      const d = 'created_at' in instance ? instance.created_at : earliestPatientEntry.created_at;
      setInitialCutoffTime(Date.parse(fixTimeZone(d)) - 1000); // One second before creation datetime of case (Patient)
    } // else, things are screwed up.  Just have null cutoff

    setJournalEntries(sortedEntries);
  }

  // Does this journal field value represent a primitive value, a model instance, or an array of model instances ?
  const assessInstanceValue = (v) => {
    let retVal = {isPrimitive: false, isModel: false, isModelArray: false};

    if (isJournalTag(v)) {
      const o = JSON.parse(v);
      if (o['_tag'] === 'MODEL') {
        retVal.isModel = true;
        retVal.modelName = o.model_name;
        retVal.id = o.id;
        return retVal;
      }
      if (o['_tag'] === 'MODEL_ARRAY') {
        retVal.isModelArray = true;
        retVal.modelName = o.model_name;
        retVal.ids = o.ids;
        return retVal;
      }
    }

    retVal.isPrimitive = true;
    return retVal;
  }

  /**
   * Format and display value of journal field
   *
   * @param v Value to display
   * @param entries Journal entries
   * @param i Index of the current journal entry
   * @param f Field Name
   * @param indent Indent for sub-objects
   * @returns {*|JSX.Element|unknown[]|string}
   */
  const displayFieldValue = (v, entries, i=0, f = null, indent=0) => {
    const INDENT_INCREMENT = 10;

    if (f && f in patientFieldLabels && 'displayFunction' in patientFieldLabels[f]) {
      return patientFieldLabels[f].displayFunction(v);
    }

    if (v === null) return <DisplayNone />;
    if (v === true) return <i><b>yes</b></i>;
    if (v === false) return <DisplayNone text='no' />;
    if (typeof v === 'string') {
      const vt = v.trim();
      if (vt === '[]' || vt === '{}') return <DisplayNone />
      if (vt === '') return <DisplayNone text="blank"/>
    }

    if (isJournalTag(v)) {
      const o = JSON.parse(v);
      if (o['_tag'] === 'MODEL') {
        return <span title={fmtModelId(o.model_name, o.id)}>{lookupModelIdLabel(o.model_name, o.id, entries, i)}</span>;
      }
      if (o['_tag'] === 'MODEL_ARRAY') {
        return o.ids.map((id) => (<div key={fmtModelId(o.model_name, id)} title={fmtModelId(o.model_name, id)}>{lookupModelIdLabel(o.model_name, id, entries, i)}</div>))
      }
      console.error('unhandled journal tag', o['_tag']);
      return v;
    }

    if (typeof v === 'string') {
      // "[array]"
      let m = v.match(/^\[.*\]$/);
      if (m) {
        try {
          const a = JSON.parse(v);
          return (<div style={{marginLeft: indent}}>
            {a.map(item => {
              return <div key={item.toString()} style={{marginBottom: "8px"}}>{displayFieldValue(item, entries, i, null, indent+INDENT_INCREMENT)}</div>
            })}
          </div>);
        } catch (e) {
          return v;
        }
      }

      // "{object}"
      m = v.match(/^\{.*\}$/);
      if (m) {
        try {
          const o = JSON.parse(v);
          return (<div style={{marginLeft: indent}}>
            {Object.keys(o).map(k =>
              <div key={k}><DisplayKey k={k} />:&nbsp;{displayFieldValue(reduceArray(o[k]), entries, i,null, indent+INDENT_INCREMENT)}</div>)}
          </div>);
        } catch (e) {
          return v;
        }
      }

      // ISO datetime - assume translate UTC to local if no time zone
      // [1] year-month-day  [2] hour:minute:second   [3] second fraction   [4] Timezone adj
      m = v.match(/^(\d\d\d\d-\d\d-\d\d)[T ](\d\d:\d\d:\d\d)(\.\d+)?(Z|([-+]\d\d:\d\d))?$/);
      if (m) {
        const vDate = new Date(v + (m[4] === undefined ? '+00:00' : ''));

        const year = vDate.getFullYear();
        const month = (vDate.getMonth() + 1).toString().padStart(2, '0');
        const day = vDate.getDate().toString().padStart(2, '0');
        let hour = vDate.getHours();
        let ampm = 'am';
        if (hour === 0) {
          hour = 12;
        } else if (hour === 12) {
          ampm = 'pm';
        } else if (hour > 12) {
          hour -= 12;
          ampm = 'pm';
        }
        const minute = vDate.getMinutes().toString().padStart(2, '0');

        return <span title={v}>{`${year}-${month}-${day}, ${hour}:${minute} ${ampm}`}</span>;
      }

      // plain string
      return v;
    }

    if (Array.isArray(v)) {
      if (v.length === 0) return <DisplayNone />
      return (<div style={{marginLeft: indent}}>
          {v.map((item, index) => <div key={index}>{displayFieldValue(item, entries, i)}</div>)}
        <div>{' '}</div>
        </div>
      )
    }

    if (typeof v === 'object') {
      if (Object.keys(v).length === 0) return <DisplayNone />
      return (<div style={{marginLeft: indent}}>
          {Object.keys(v).map(k => {
            return <div key={k}><DisplayKey k={k} />:&nbsp;{displayFieldValue(reduceArray(v[k]), entries, i,null, indent+INDENT_INCREMENT)}</div>})
          }
        <div>{' '}</div>
        </div>
      )
    }

    // primitive value
    return v;
  }

  // Is given journal entry the first (earliest) one for its model+id ?
  const isInitialEntry = (entries, startIndex) => {
    const modelName = entries[startIndex].model_name;
    const id = entries[startIndex].row_id;

    for (let i = startIndex + 1; i < entries.length; i++) {
      if (entries[i].model_name === modelName && entries[i].row_id === id && entries[i].entry_type === 'F') return false;
    }

    return true;
  }

  // return true if we consider v1 and v2 to be different field values.
  const valuesAreDifferent = (v1, v2) => {
    if (v1 === v2) return false;

    if (v1 === '{"_tag": "MODEL", "model_name": "User", "id": null}') v1 = null;
    if (v2 === '{"_tag": "MODEL", "model_name": "User", "id": null}') v2 = null;

    if ( ((v1 === null) || (typeof v1 === 'string' && v1.trim() === ''))
      && ((v2 === null) || (typeof v2 === 'string' && v2.trim() === ''))) return false;

    if (v1 === null && v2 !== null) return true;
    if (v1 !== null && v2 === null) return true;

    if (typeof v1 === 'string' && typeof v2 === 'string') {
      if (v1.trim() === v2.trim()) return false;
      if (v1 === v2 + "+00:00" || v2 === v1 + "+00:00") return false;

      // If values are JSON, compare them value by value in case order or spacing differs
      const m1 = v1.match(/^\{.*\}$/);
      const m2 = v2.match(/^\{.*\}$/);
      if (m1 && m2) {
        try {
          const o1 = JSON.parse(v1);
          const o2 = JSON.parse(v2);
          const o1keys = Object.keys(o1);
          if (o1keys.length !== Object.keys(o2).length) return true;
          for (let i=0; i < o1keys.length; i += 1)
            if (!(o1keys[i] in o2) || o1[o1keys[i]] !== o2[o1keys[i]]) return true;
          return false;
        } catch (e) {
          return (v1 !== v2);
        }
      }
    }

    return (v1 !== v2);
  }

  const entryHasChanges = (modelName, instance, entries, index, showDefault=false) => {
    for (const f of Object.keys(instance)) {
      if (!ignoreThisAttribute(f, modelName)) {
        const rv = findPrevFieldValue(entries, index + 1, modelName, entries[index].row_id, f, instance[f]);
        if (rv.status) {
          if (valuesAreDifferent(rv.value, instance[f]) && (showDefault || !valuesAreBothDefaults(modelName, f, rv.value, instance[f]))) return true;
        } else {
          return true;
        }
      }
    }
    return false;
  }

  const valueTransitionRow = (entries, index, modelName, f, beforeValue, afterValue) => (
    <tr key={f}>
      <td style={{width: "180px", paddingLeft: 0, whiteSpace: 'nowrap'}}>
        {displayFieldName(modelName, f)}
      </td>
      <td>{displayFieldValue(beforeValue, entries, index, f)}</td>
      <td>></td>
      <td>{displayFieldValue(afterValue, entries, index, f)}</td>
    </tr>
  );

  // Display Journal instance at give row index.
  // It's a delta, which just contains fields that have changed, not complete model row data.
  // Display shows before and after values of each field.
  const displayDeltaTable = (modelName, instance, entries, index, showDefault=false) => {
    return (
        <table>
          <tbody>
            {Object.keys(instance).map((f) => {
              if (ignoreThisAttribute(f, modelName)) return null;

              const rv = findPrevFieldValue(entries, index + 1, modelName, entries[index].row_id, f, instance[f]);
              if (rv.status) {
                // Previous value different (as expected).  Show the transition.
                if (valuesAreDifferent(rv.value, instance[f]) && (showDefault || !valuesAreBothDefaults(modelName, f, rv.value, instance[f])))
                  return valueTransitionRow(entries, index, modelName, f, rv.value, instance[f]);
                return null;
              } else if (instance[f] !== null) {
                if (showDefault || !fieldHasDefaultValue(modelName, f, instance[f]))
                  return valueTransitionRow(entries, index, modelName, f,null, instance[f]);
                return null;
              } else {
                return null;
              }
            })}
          </tbody>
        </table>
    )
  }


  // eslint-disable-next-line eqeqeq
  const equalOrBothNullBlank = (v1, v2) => (v1 == v2 || (v1 === null && v2 === '') || (v1 === '' && v2 === null));

  const fieldHasDefaultValue = (modelName, fieldName, fieldValue) => {
    if (modelName in defaultAttributesModel && fieldName in defaultAttributesModel[modelName]) {
      const defaultValue = defaultAttributesModel[modelName][fieldName];
      if (Array.isArray(defaultValue))
        for (let dv of defaultValue) if (equalOrBothNullBlank(fieldValue, dv)) return true;
      return equalOrBothNullBlank(fieldValue, defaultValue);
    }
    return false;
  }

  const valuesAreBothDefaults = (modelName, fieldName, v1, v2) => (
    fieldHasDefaultValue(modelName, fieldName, v1) && fieldHasDefaultValue(modelName, fieldName, v2)
  );

  // Did the given entry occur after case was created?  True/False.
  // (If false, don't show entry)
  const entryAfterCutoff = (entry) => {
    return (initialCutoffTime === null || (Date.parse(fixTimeZone(entry.created_at)) > initialCutoffTime));
  };

  // For first full entry of model, show all its non-default values
  const displayInitialTable = (modelName, instance, entries, index, showDefault=false) => {
    return (
      <>
        <table>
          <tbody>
            {displayAllFieldNameAndValuesOfInstance(modelName, instance, entries, index, showDefault)}
          </tbody>
        </table>
      </>
    );
  }

  // Display Journal instance at given row index.  It contains full row of model data.
  // Display shows before and after values of each field.
  const displayFullTable = (modelName, instance, entries, index, showDefault=false) => {
    return (
        <table>
          <tbody>
            {Object.keys(instance).map((f) => {
              if (ignoreThisAttribute(f, modelName)) return null;

              const rv = findPrevFieldValue(entries, index + 1, modelName, entries[index].row_id, f, instance[f]);
              if (rv.status) {
                // If previous value different, show the transition.
                if (valuesAreDifferent(rv.value, instance[f]) && (showDefault || !valuesAreBothDefaults(modelName, f, rv.value, instance[f])))
                  return valueTransitionRow(entries, index, modelName, f, rv.value, instance[f]);
                return null;
              }
              // No prior entry for this field
              if (showDefault || !fieldHasDefaultValue(modelName, f, instance[f]))
                return valueTransitionRow(entries, index, modelName, f,null, instance[f]);
              return null;
            })}
          </tbody>
        </table>
    )
  }

  // Display all model field name & values in Journal instance (for INITIAL or DELETED entries)
  // If showDefault is false, exclude default values
  const displayAllFieldNameAndValuesOfInstance = (modelName, instance, entries, index, showDefault=false) => {
    return Object.keys(instance).map((f) => {
      if (ignoreThisAttribute(f, modelName)) return null;
      if (!showDefault && fieldHasDefaultValue(modelName, f, instance[f])) return null;
      return (
        <tr key={f}>
          <td style={{width: "180px", paddingLeft: 0, whiteSpace: 'nowrap'}}>{displayFieldName(modelName, f)}</td>
          <td>{displayFieldValue(instance[f], entries, index, f)}</td>
        </tr>
      )
    })
  }

  return (
    <Page>
      <h4>Change Log</h4>
      <PageContent>
        {journalEntries.length === 0 ? <div>...</div> : (
          <Card>
            <CardBody>
              <div className="with-table">
                <table className="table table-sm">
                  <thead>
                  <tr>
                    <th>Time</th>
                    <th>IP Address</th>
                    <th>User</th>
                    <th>Instance</th>
                    <th><div style={{display: 'inline-block', width: "180px"}}>Field</div>old&nbsp;&nbsp;&nbsp; > &nbsp;&nbsp;&nbsp;new</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    journalEntries.map((entry, entryIndex) => {
                      const instance = parseInstanceFromJournalEntry(entry);
                      if (!instance) {
                        return (
                          <tr key={'entry-' + entry.id}>
                            <td><TimeCreated value={fixTimeZone(entry.created_at)}/></td>
                            <td>{entry.ip_address}</td>
                            <td>{entry.username}</td>
                            <td>
                                INVALID JOURNAL ENTRY
                            </td>
                          </tr>
                        );
                      }

                      // Don't show entries occurring before initial Patient (case) entry
                      if (!entryAfterCutoff(entry)) return null;

                      // Don't show empty entries
                      if (entry.entry_type === 'D' && !entryHasChanges(entry.model_name, instance, journalEntries, entryIndex)) return null;
                      const isInitial = isInitialEntry(journalEntries, entryIndex);
                      if (entry.entry_type === 'F' && !isInitial && !entryHasChanges(entry.model_name, instance, journalEntries, entryIndex)) return null;

                      return (
                        <tr key={'entry-' + entry.id + ('case_log_entry_type' in entry ? entry.case_log_entry_type : '')}>
                          <td style={{whiteSpace: "nowrap", cursor: "pointer"}}
                              title={`#${entry.id}${'case_log_entry_type' in entry ? entry.case_log_entry_type : ''} ${fixTimeZone(entry.created_at)}`}
                            ><TimeCreated value={fixTimeZone(entry.created_at)}/></td>
                          <td style={{whiteSpace: "nowrap"}}>{entry.ip_address}</td>
                          <td style={{whiteSpace: "nowrap"}}>{entry.username}</td>
                          <td style={{whiteSpace: "nowrap"}} title={fmtModelId(entry.model_name, entry.row_id)}>
                            {lookupModelIdLabel(entry.model_name, entry.row_id, journalEntries, entryIndex)}
                          </td>
                          <td>
                            {entry.entry_type !== 'X' ? null : (
                                <>
                                 <div>&nbsp;&nbsp;&nbsp;DELETED</div>
                                 <table>
                                    <tbody>
                                      {displayAllFieldNameAndValuesOfInstance(entry.model_name, instance, journalEntries, entryIndex)}
                                    </tbody>
                                 </table>
                                </>
                              )}
                            {entry.entry_type === 'D' ? displayDeltaTable(entry.model_name, instance, journalEntries, entryIndex) : null}
                            {entry.entry_type === 'F' && isInitial ? displayInitialTable(entry.model_name, instance, journalEntries, entryIndex) : null}
                            {entry.entry_type === 'F' && !isInitial ? displayFullTable(entry.model_name, instance, journalEntries, entryIndex) : null}
                          </td>
                        </tr>
                      );
                    })
                  }
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        )}
      </PageContent>
    </Page>
  )
}