import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {deleteClinician, getClinicianList} from 'actions/clinician';
import TablePlaceholder from 'components/Placeholders/TablePlaceholder';
import {clinicianModalOpen} from "actions/modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Card, CardBody, CardHeader, Col, Row} from "reactstrap";

import {CardTable, Page, PageContent, PageHeader, TableCellWithLink, TableRowWithLink} from 'components/Views';
import Moment from "moment-timezone";
import {
  IfAuthSuperReadonly,
  IfNotAuthSuperReadonly,
  isAuthDistributor,
  isAuthSuper,
  isAuthSuperAdminOrReadonly
} from "components/RBAC";
import ClinicianModal from 'components/modals/ClinicianModal';

const SIX_MONTHS_MS = 31 * 24 * 60 * 60 * 1000;

function List(props) {
  const [hideInactive, setHideInactive] = useState(true);
  const [itemsToShow, setItemsToShow] = useState([]);
  const {items} = props;

  const shouldHideThisClinician = (item) => {
    if (!hideInactive) return false;  // Don't hide.  "hideInactive" checkbox is unchecked.
    if (!item.provider_latest_case_created) return true;  // Hide. Clinician's clinic has no cases.
    return (Date.now() - Date.parse(item.provider_latest_case_created) > SIX_MONTHS_MS);  // Hide if clinic's latest case created over 6 months ago
  };

  useEffect(() => {
    props.getList();
  }, []);   // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    props.getList();
  }, [props.selected_provider_id]);   // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (items) {  // items empty until loading by getList finishes
      const itms = [];
      for (const item of items) {
        if (!isAuthSuperAdminOrReadonly(props.user) || !shouldHideThisClinician(item)) {
          itms.push(item);
        }
      }
      setItemsToShow(itms);
    }
  }, [items, hideInactive]);  // eslint-disable-line react-hooks/exhaustive-deps

  const deleteItem = (item, index) => {
    if (
      item.biopsy_samples_count > 0 ||
      item.loading_samples_count > 0 ||
      item.cases_count > 0
    ) {
      alert('Provider has samples/cases -- may not be deleted.')
    } else {
      const result = window.confirm("Are you sure?");
      if (result) {
        props.deleteClinician(item.id, index);
      }
    }
  }

  return (
    <Page>
      <PageHeader>
        <Row className="align-items-center justify-content-between">
          <Col>
            <h3 className="page-title">Providers</h3>
          </Col>
          <Col md="auto">
            <IfNotAuthSuperReadonly>
                <Button
                  color="light"
                  onClick={() => {
                    const provider = window.localStorage.getItem('provider');  // Retrieve selected clinic from localStorage

                    if (isAuthSuper(props.user)) {
                      // Super-admin must have a clinic selected
                      if (provider) {
                        props.clinicianModalOpen();
                      } else {
                        alert('Please select a clinic first');
                      }
                    } else if (isAuthDistributor(props.user)) {
                      // Distributor must have at least one clinic created AND selected
                      if (props.providers && props.providers.length <= 0) {
                        alert('Create a clinic first');
                      } else if (!provider) {
                        alert('Please select a clinic first');
                      } else {
                        props.clinicianModalOpen();
                      }
                    } else {
                      // Regular users can proceed without a clinic
                      props.clinicianModalOpen();
                    }
                  }}
                >
                <FontAwesomeIcon icon="plus"/>{' '}Create
              </Button>
            </IfNotAuthSuperReadonly>
          </Col>
        </Row>
        <Row>
          {isAuthSuperAdminOrReadonly(props.user) && (
            <Col>
              &nbsp;&nbsp;
              <input type="checkbox" checked={hideInactive} onChange={() => setHideInactive(!hideInactive)}/>
              &nbsp;Hide providers from inactive clinics
            </Col>
          )}
        </Row>
      </PageHeader>

      <PageContent>
        <ClinicianModal/>
        {
          items ? (
            <div>
              {
                itemsToShow.length > 0 ? (
                  <Card>
                    <CardHeader>
                      <Row>
                        <Col>
                          <h6 style={{margin: 0}}>Total: {items.length}</h6>
                        </Col>
                        <Col md="auto">
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <CardTable
                        id={'patients'}
                        columns={['Name', 'Email', 'Phone', 'Created', '']}
                      >
                        {
                          itemsToShow.map((item, i) => {
                            const creationDate = Moment.utc(item.created_at, 'YYYY-MM-DD hh:mm:ss').local().format('MM/DD/YYYY');
                            return (
                              <>
                              <IfNotAuthSuperReadonly>
                                <TableRowWithLink
                                  key={'list-item-' + i}
                                  onClick={() => props.clinicianModalOpen(item, i)}
                                >
                                  <TableCellWithLink>
                                    {item.first_name}&nbsp;{item.last_name}
                                  </TableCellWithLink>

                                  <TableCellWithLink>
                                    {item.email}
                                  </TableCellWithLink>

                                  <TableCellWithLink>
                                    {item.phone}
                                  </TableCellWithLink>

                                  <TableCellWithLink width={120}>
                                    {creationDate}
                                  </TableCellWithLink>

                                  <TableCellWithLink>
                                    <div className="text-right text-danger">
                                      <button
                                        className="link-button text-danger"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          e.preventDefault();
                                          deleteItem(item, i)
                                        }}
                                      >
                                        <FontAwesomeIcon icon="trash-alt"/>
                                      </button>
                                    </div>
                                  </TableCellWithLink>
                                </TableRowWithLink>
                              </IfNotAuthSuperReadonly>
                              <IfAuthSuperReadonly>
                                <tr key={'list-item-' + i}>
                                  <td>
                                    {item.first_name}&nbsp;{item.last_name}
                                  </td>

                                  <td>
                                    {item.email}
                                  </td>

                                  <td>
                                    {item.phone}
                                  </td>

                                  <td width={120}>
                                    {creationDate}
                                  </td>
                                </tr>
                              </IfAuthSuperReadonly>
                              </>
                            )
                          })
                        }
                      </CardTable>
                    </CardBody>
                  </Card>
                ) : (
                  'No providers'
                )
              }
            </div>
          ) : <TablePlaceholder rowHeight={46}/>
        }
      </PageContent>
    </Page>
  );
}

export default connect(
  state => ({
    user: state.auth,
    items: state.clinician.clinicians,
    clinician: state.clinician.clinician,
    selected_provider_id: state.common.selected_provider_id,
    providers: state.provider.items
  }),
  {
    getList: getClinicianList,
    clinicianModalOpen: clinicianModalOpen,
    deleteClinician: deleteClinician
  }
)(List);
