import React from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import {Button, Col, FormFeedback, FormGroup, Label, Row} from "reactstrap";
import classNames from "classnames";
import moment from 'moment';
import Moment from "moment";

const isValidMoney = value => {
  if (value === undefined) return true;
  if (value !== '') {
    const m = value.match(/^\d*(\.?$)|(\.\d?\d$)/);
    if (m) return true;
  }
  return false;
}

const isValidDate = value => {
  if (value === undefined) return true;
  if (value !== '') {
    const m = value.match(/^20\d\d-\d\d-\d\d$/);
    if (m) {
      const now = new Date();
      return (now[Symbol.toPrimitive]('number') > Date.parse(value));  // is current/past date?
    }
  }
  return false;
}

const hasNoTests = patient => !(patient.pgt_a || patient.pgt_m || patient.pgt_s || patient.pgt_a_plus || patient.m2);

export default (props) => {
  const { initialValues } = props;

  return (
    <Formik
      initialValues={{
        id: initialValues.id,
        account_manager: initialValues.account_manager || '',
        billing_type: initialValues.billing_type || '',
        billing_email: initialValues.billing_email || '',
        intake_fee: initialValues.intake_fee,
        setup_fee: initialValues.setup_fee,
        per_sample_fee: initialValues.per_sample_fee,
        shipping_fee: initialValues.shipping_fee,
        num_biopsies: initialValues.num_biopsies,
        case_billed_amount: initialValues.case_billed_amount,
        case_total_fee: initialValues.case_total_fee,
        case_paid_amount: initialValues.case_paid_amount,
        invoice_number: initialValues.invoice_number,
        invoice_billed_date: initialValues.invoice_billed_date,
        invoice_paid_date: initialValues.invoice_paid_date,
        intake_paid_date: initialValues.intake_paid_date,
        intake_fee_sent_date: initialValues.intake_fee_sent_date,
        admin_and_analysis_fee: initialValues.admin_and_analysis_fee,
        setup_sent_date: initialValues.setup_sent_date,
        setup_paid_date: initialValues.setup_paid_date,
        test_update_date: initialValues.test_update_date,
        fee_after_test_update: initialValues.fee_after_test_update,
        paid_amount_after_test_update: initialValues.paid_amount_after_test_update,
        invoice_number_after_test_update: initialValues.invoice_number_after_test_update,
        invoice_billed_date_after_test_update: initialValues.invoice_billed_date_after_test_update,
        invoice_paid_date_after_test_update: initialValues.invoice_paid_date_after_test_update,
        billing_note: initialValues.billing_note,
      }}

      onSubmit={
        (values, {setSubmitting}) => {
          values.invoice_billed_date = values.invoice_billed_date ? moment(values.invoice_billed_date).format('YYYY-MM-DD') : null;
          values.invoice_paid_date = values.invoice_paid_date ? moment(values.invoice_paid_date).format('YYYY-MM-DD') : null;
          values.intake_paid_date = values.intake_paid_date ? moment(values.intake_paid_date).format('YYYY-MM-DD') : null;
          values.intake_fee_sent_date = values.intake_fee_sent_date ? moment(values.intake_fee_sent_date).format('YYYY-MM-DD') : null;
          values.setup_sent_date = values.setup_sent_date ? moment(values.setup_sent_date).format('YYYY-MM-DD') : null;
          values.setup_paid_date = values.setup_paid_date ? moment(values.setup_paid_date).format('YYYY-MM-DD') : null;
          values.invoice_billed_date_after_test_update = values.invoice_billed_date_after_test_update ? moment(values.invoice_billed_date_after_test_update).format('YYYY-MM-DD') : null;
          values.invoice_paid_date_after_test_update = values.invoice_paid_date_after_test_update ? moment(values.invoice_paid_date_after_test_update).format('YYYY-MM-DD') : null;
          props.onSubmit(values);
          setSubmitting(false)
        }
      }

      validate = {(values) => {
        const errors={};
        const INVALID_AMT_MSG = 'Invalid Amount - must be whole number or x.xx format';
        if (!isValidMoney(values.intake_fee)) errors.intake_fee = INVALID_AMT_MSG;
        if (!isValidMoney(values.setup_fee)) errors.setup_fee = INVALID_AMT_MSG;
        if (!isValidMoney(values.per_sample_fee)) errors.per_sample_fee = INVALID_AMT_MSG;
        if (!isValidMoney(values.shipping_fee)) errors.shipping_fee = INVALID_AMT_MSG;
        if (!isValidMoney(values.admin_and_analysis_fee)) errors.admin_and_analysis_fee = INVALID_AMT_MSG;
        if (!isValidMoney(values.case_total_fee)) errors.case_total_fee = INVALID_AMT_MSG;
        if (!isValidMoney(values.case_billed_amount)) errors.case_billed_amount = INVALID_AMT_MSG;
        if (!isValidMoney(values.case_paid_amount)) errors.case_paid_amount = INVALID_AMT_MSG;
        if (!isValidMoney(values.fee_after_test_update)) errors.fee_after_test_update = INVALID_AMT_MSG;
        if (!isValidMoney(values.paid_amount_after_test_update)) errors.paid_amount_after_test_update = INVALID_AMT_MSG;

        const INVALID_DATE_MSG = 'Invalid Date - must be in past/present with valid year';
        if (!isValidDate(values.invoice_billed_date)) errors.invoice_billed_date = INVALID_DATE_MSG;
        if (!isValidDate(values.invoice_paid_date)) errors.invoice_paid_date = INVALID_DATE_MSG;
        if (!isValidDate(values.intake_paid_date)) errors.intake_paid_date = INVALID_DATE_MSG;
        if (!isValidDate(values.intake_fee_sent_date)) errors.intake_fee_sent_date = INVALID_DATE_MSG;
        if (!isValidDate(values.invoice_billed_date_after_test_update)) errors.invoice_billed_date_after_test_update = INVALID_DATE_MSG;
        if (!isValidDate(values.invoice_paid_date_after_test_update)) errors.invoice_paid_date_after_test_update = INVALID_DATE_MSG;

        return errors;
      }}
    >
      {
        ({errors, touched, values, isSubmitting, setFieldValue, handleChange}) => {
          return (
            <Form autoComplete="off">
              <FormGroup row className="align-items-center">
                <Label for="account_manager" sm={4}>Account Manager</Label>
                <Col sm={8}>
                  <Field
                    name="account_manager"
                    component="select"
                    className="form-control"
                  >
                    <option value={''}></option>
                    <option value={'Global'}>Global</option>
                    <option value={'EAST'}>EAST</option>
                    <option value={'West'}>West</option>
                    <option value={'Midwest'}>Midwest</option>
                    <option value={'LATAM'}>LATAM</option>
                    <option value={'Canada'}>Canada</option>
                  </Field>
                </Col>
              </FormGroup>

              <FormGroup row className="align-items-center">
                <Label for="billing_type" sm={4}>Billing Type</Label>
                <Col sm={8}>
                  <Field
                    name="billing_type"
                    component="select"
                    className="form-control"
                  >
                    <option value={''}>Select</option>
                    <option value={'Client Bill'}>Client Bill</option>
                    <option value={'Patient Bill'}>Patient Bill</option>
                    <option value={'Split Bill'}>Split Bill</option>
                    <option value={'Progyny'}>Progyny</option>
                    <option value={'Maven'}>Maven</option>
                    <option value={'WINFertility'}>WINFertility</option>
                    <option value={'other'}>other</option>
                  </Field>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="billing_email" sm={4}>Billing Email</Label>
                <Col sm={8}>
                  <Field
                    type="email"
                    name="billing_email"
                    className={classNames('form-control', {
                      'is-invalid': touched.billing_email && !!errors.billing_email
                    })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="billing_email" component="span" />
                  </FormFeedback>
                </Col>
              </FormGroup>

           {(initialValues.pgt_m || initialValues.pgt_s || hasNoTests(initialValues)) && (
              <FormGroup row>
                <Label for="intake_fee" sm={4}>Intake Fee</Label>
                <Col sm={8}>
                  <Field
                    type="text"
                    name="intake_fee"
                    className={classNames('form-control', {
                      'is-invalid': touched.intake_fee && !!errors.intake_fee
                    })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="intake_fee" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>
           )}

              <FormGroup row>
                <Label for="setup_fee" sm={4}>Set up Fee</Label>
                <Col sm={8}>
                  <Field
                    type="text"
                    name="setup_fee"
                    className={classNames('form-control', {
                      'is-invalid': touched.setup_fee && !!errors.setup_fee
                    })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="setup_fee" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

             <FormGroup row>
                <Label for="setup_sent_date" sm={4}>Setup Sent Date</Label>
                <Col sm={8}>
                  <input
                    type="date"
                    name="setup_sent_date"
                    className={classNames('form-control', {
                      'is-invalid': touched.setup_sent_date && !!errors.setup_sent_date
                    })}
                    onChange={(d) => {setFieldValue('setup_sent_date', d.target.value)}}
                    value={values.setup_sent_date || undefined}
                  />
                  <FormFeedback>
                    <ErrorMessage name="setup_sent_date" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="setup_paid_date" sm={4}>Setup Paid Date</Label>
                <Col sm={8}>
                  <input
                    type="date"
                    name="setup_paid_date"
                    className={classNames('form-control', {
                      'is-invalid': touched.setup_paid_date && !!errors.setup_paid_date
                    })}
                    onChange={(d) => {setFieldValue('setup_paid_date', d.target.value)}}
                    value={values.setup_paid_date || undefined}
                  />
                  <FormFeedback>
                    <ErrorMessage name="setup_paid_date" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="per_sample_fee" sm={4}>Per-Sample Fee</Label>
                <Col sm={8}>
                  <Field
                    type="text"
                    name="per_sample_fee"
                    className={classNames('form-control', {
                      'is-invalid': touched.per_sample_fee && !!errors.per_sample_fee
                    })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="per_sample_fee" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="shipping_fee" sm={4}>Shipping Fee</Label>
                <Col sm={8}>
                  <Field
                    type="text"
                    name="shipping_fee"
                    className={classNames('form-control', {
                      'is-invalid': touched.shipping_fee && !!errors.shipping_fee
                    })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="shipping_fee" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="num_biopsies" sm={4}>Number of Biopsies</Label>
                <Col sm={8}>
                  <Field
                    type="number"
                    name="num_biopsies"
                    min="0"
                    step="1"
                    className={classNames('form-control', {
                      'is-invalid': touched.num_biopsies && !!errors.num_biopsies
                    })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="num_biopsies" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="case_total_fee" sm={4}>Case Total Fee</Label>
                <Col sm={8}>
                  <Field
                    type="text"
                    name="case_total_fee"
                    className={classNames('form-control', {
                      'is-invalid': touched.case_total_fee && !!errors.case_total_fee
                    })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="case_total_fee" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="case_billed_amount" sm={4}>Case Billed Amount</Label>
                <Col sm={8}>
                  <Field
                    type="text"
                    name="case_billed_amount"
                    className={classNames('form-control', {
                      'is-invalid': touched.case_billed_amount && !!errors.case_billed_amount
                    })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="case_billed_amount" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="case_paid_amount" sm={4}>Case Paid Amount</Label>
                <Col sm={8}>
                  <Field
                    type="text"
                    name="case_paid_amount"
                    className={classNames('form-control', {
                      'is-invalid': touched.case_paid_amount && !!errors.case_paid_amount
                    })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="case_paid_amount" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="invoice_billed_date" sm={4}>Invoice Billed Date</Label>
                <Col sm={8}>
                  <input
                    type="date"
                    name="invoice_billed_date"
                    className={classNames('form-control', {
                      'is-invalid': touched.invoice_billed_date && !!errors.invoice_billed_date
                    })}
                    onChange={(d) => {setFieldValue('invoice_billed_date', d.target.value)}}
                    value={values.invoice_billed_date || undefined}
                  />
                  <FormFeedback>
                    <ErrorMessage name="invoice_billed_date" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="invoice_paid_date" sm={4}>Invoice Paid Date</Label>
                <Col sm={8}>
                  <input
                    type="date"
                    name="invoice_paid_date"
                    className={classNames('form-control', {
                      'is-invalid': touched.invoice_paid_date && !!errors.invoice_paid_date
                    })}
                    onChange={(d) => {setFieldValue('invoice_paid_date', d.target.value)}}
                    value={values.invoice_paid_date || undefined}
                  />
                  <FormFeedback>
                    <ErrorMessage name="invoice_paid_date" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

           {(initialValues.pgt_m || initialValues.pgt_s || hasNoTests(initialValues)) && (
              <FormGroup row>
                <Label for="intake_paid_date" sm={4}>Intake Fee Paid Date</Label>
                <Col sm={8}>
                  <input
                    type="date"
                    name="intake_paid_date"
                    className={classNames('form-control', {
                      'is-invalid': touched.intake_paid_date && !!errors.intake_paid_date
                    })}
                    onChange={(d) => {setFieldValue('intake_paid_date', d.target.value)}}
                    value={values.intake_paid_date || undefined}
                  />
                  <FormFeedback>
                    <ErrorMessage name="intake_paid_date" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>
           )}

              <FormGroup row>
                <Label for="invoice_number" sm={4}>Invoice Number</Label>
                <Col sm={8}>
                  <input
                    type="text"
                    name="invoice_number"
                    className={classNames('form-control', {
                      'is-invalid': touched.invoice_number && !!errors.invoice_number
                    })}
                    onChange={(d) => {setFieldValue('invoice_number', d.target.value)}}
                    value={values.invoice_number || undefined}
                  />
                  <FormFeedback>
                    <ErrorMessage name="invoice_number" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

           {(initialValues.pgt_m || initialValues.pgt_s || hasNoTests(initialValues)) && (
              <FormGroup row>
                <Label for="intake_fee_sent_date" sm={4}>Intake Fee Sent Date</Label>
                <Col sm={8}>
                  <input
                    type="date"
                    name="intake_fee_sent_date"
                    className={classNames('form-control', {
                      'is-invalid': touched.intake_fee_sent_date && !!errors.intake_fee_sent_date
                    })}
                    onChange={(d) => {setFieldValue('intake_fee_sent_date', d.target.value)}}
                    value={values.intake_fee_sent_date || undefined}
                  />
                  <FormFeedback>
                    <ErrorMessage name="intake_fee_sent_date" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>
           )}

           {(initialValues.pgt_m || initialValues.pgt_s || hasNoTests(initialValues)) && (
              <FormGroup row>
                <Label for="admin_and_analysis_fee" sm={4}>Admin & Analysis Fee</Label>
                <Col sm={8}>
                  <Field
                    type="text"
                    name="admin_and_analysis_fee"
                    className={classNames('form-control', {
                      'is-invalid': touched.admin_and_analysis_fee && !!errors.admin_and_analysis_fee
                    })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="admin_and_analysis_fee" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>
           )}

              <FormGroup row>
                <Label for="billing_note" sm={4}>Rebiopsy Note</Label>
                <Col sm={8}>
                  <Field
                    type="text"
                    name="billing_note"
                    className={classNames('form-control', {
                      'is-invalid': touched.billing_note && !!errors.billing_note
                    })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="billing_note" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

          {initialValues.test_update_date && (
            <>
              <FormGroup row>
                <Col sm={8}>
                  <Label>After Tests Updated </Label>
                  &nbsp;{Moment(initialValues.test_update_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}:
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="fee_after_test_update" sm={4}>Updated Fee</Label>
                <Col sm={8}>
                  <Field
                    type="text"
                    name="fee_after_test_update"
                    className={classNames('form-control', {
                      'is-invalid': touched.fee_after_test_update && !!errors.fee_after_test_update
                    })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="fee_after_test_update" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="paid_amount_after_test_update" sm={4}>Updated Paid Amount</Label>
                <Col sm={8}>
                  <Field
                    type="text"
                    name="paid_amount_after_test_update"
                    className={classNames('form-control', {
                      'is-invalid': touched.paid_amount_after_test_update && !!errors.paid_amount_after_test_update
                    })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="paid_amount_after_test_update" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="invoice_number_after_test_update" sm={4}>Updated Invoice Number</Label>
                <Col sm={8}>
                  <Field
                    type="text"
                    name="invoice_number_after_test_update"
                    className={classNames('form-control', {
                      'is-invalid': touched.invoice_number_after_test_update && !!errors.invoice_number_after_test_update
                    })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="invoice_number_after_test_update" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="invoice_billed_date_after_test_update" sm={4}>Updated Invoice Billed Date</Label>
                <Col sm={8}>
                  <input
                    type="date"
                    name="invoice_billed_date_after_test_update"
                    className={classNames('form-control', {
                      'is-invalid': touched.invoice_billed_date_after_test_update && !!errors.invoice_billed_date_after_test_update
                    })}
                    onChange={(d) => {setFieldValue('invoice_billed_date_after_test_update', d.target.value)}}
                    value={values.invoice_billed_date_after_test_update || undefined}
                  />
                  <FormFeedback>
                    <ErrorMessage name="invoice_billed_date_after_test_update" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="invoice_paid_date_after_test_update" sm={4}>Updated Invoice Paid Date</Label>
                <Col sm={8}>
                  <input
                    type="date"
                    name="invoice_paid_date_after_test_update"
                    className={classNames('form-control', {
                      'is-invalid': touched.invoice_paid_date_after_test_update && !!errors.invoice_paid_date_after_test_update
                    })}
                    onChange={(d) => {setFieldValue('invoice_paid_date_after_test_update', d.target.value)}}
                    value={values.invoice_paid_date_after_test_update || undefined}
                  />
                  <FormFeedback>
                    <ErrorMessage name="invoice_paid_date_after_test_update" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>
            </>
          )}


              <Row style={{marginTop: 20}} className="justify-content-start">
                <Col md="auto">
                  <Button
                      type="submit"
                      color="success"
                      disabled={isSubmitting || !!Object.keys(errors).length}
                  >
                      Submit
                  </Button>
                </Col>
                <Col md="auto">
                    <Button color="light" onClick={() => props.onCancel()}>Cancel</Button>
                </Col>
              </Row>
            </Form>
          )
        }
      }
    </Formik>
  )
}